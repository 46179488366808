.section-page-header{
	margin-top: 3.3rem;
	@media (max-width: $sw-md-max) {
		margin-top: 5.2rem;
	}
	@media (max-width: $sw-xs-max) {
		margin-top: 5.6rem;
	}
	.box-title{
		margin-top: 1.4rem;
	}
}