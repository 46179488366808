.distributors {
    &__top-info {
        margin-top: 2.3rem;
        border-top: 1px solid $athensGray;
        text-align: center;
        margin-bottom: 8rem;
        .s-title-box__wrapper.box__bordered {
            &:after {
                opacity: 1;
            }
        }
        .btn {
            width: 160px;
        }
    }
    &__text {
        &-lg, &-sm {
            color: $stormGray;
            display: inline-block;
            width: 100%;
            text-align: center;
        }
        &-lg {
            font-size: 1.4rem;
            text-transform: uppercase;
            margin-bottom: 7px;
        }
        &-sm {
            font-size: 1.2rem;
        }
    }
    &__chevron {
        margin: 1.4rem 0 2.6rem 0;
        position: relative;
        height: 10px;
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: #4d4d57 transparent transparent transparent;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }

    &__header {
        min-height: 6rem;
        background-color: $athensGray;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__title {
        color: $charade;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.5rem;
        text-transform: uppercase;
        letter-spacing: 1.4px;
    }
    &__body {
        padding: 6.3rem 1rem 1.6rem 1rem;
        @media (max-width: $sw-xs-max) {
            padding: 5.5rem 2.1rem 1.5rem 2.1rem;
        }
        .mp-wrap {
            display: flex;
            max-width: 600px;
            width: 100%;
            margin: 0 auto 0 auto;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 4rem 4rem;
            margin-bottom: 5rem;
        }
        .mp-link {
            display: flex;
            width: 280px;
            color: #ffffff;
            justify-content: center;
            align-items: center;
            height: 60px;
            font-size: 14px;
            @media (max-width: $sw-sm-tin) {
                width: 100%;
                max-width: 400px;
            }
        }
        .mp-link img {
            margin-right: 24px;
            width: 24px;
            height: 24px;
        }
    }
    &__wrapper {
        max-width: 1275px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    &__block {
        width: calc(100% / 3);
        margin-bottom: 4.7rem;
        padding-right: 2.5rem;
        padding-left: 2rem;
        @media (max-width: $sw-sm-max) {
            width: 50%;
        }
        @media (max-width: $sw-xs-max) {
            width: 100%;
            padding-left: 0;
        }
    }
    &__item, &__name {
        display: inline-block;
        width: 100%;
        margin-bottom: 7px;
        color: $stormGray;
        text-transform: uppercase;
        @media (max-width: $sw-xs-max) {
            margin-bottom: 12px;
        }
    }
    &__item {
        font-size: 1rem;
        font-weight: 400;
        a {
            color: $stormGray;
            text-decoration: none;
            transition: color .3s ease;
            &:hover {
                @media (min-width: $sw-sm-min) {
                    color: darken($stormGray, 50%);
                }
            }
        }
        @media (max-width: $sw-xs-max) {
            font-size: 1.4rem;
        }
    }
    &__status_exclusive {
        width: 250px;
        padding: 5px 10px;
        background-color: #000000;
        color: #f0f0f0;
        text-align: center;
    }
    &__status_gold {
        width: 250px;
        padding: 5px 10px;
        background-color: #FFD700;
        color: #000000;
        text-align: center;
    }
    &__status_silver {
        width: 250px;
        padding: 5px 10px;
        background-color: #D3D3D3;
        color: #000000;
        text-align: center;
    }
    &__name {
        font-size: 1.2rem;
        font-weight: 900;
        @media (max-width: $sw-xs-max) {
            font-size: 1.7rem;
        }
    }
    &__links {
        &-wrapper {
            display: flex;
            align-items: center;
        }
        &-item {
            width: 30px;
            height: 30px;
            background-color: $athensGray;
            margin-right: 10px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: background-color .3s ease;
            &:hover {
                @media (min-width: $sw-md-min) {
                    background-color: darken($athensGray, 4%);
                }
            }
            .icon {
                color:#b2b2c3;
            }
        }
    }
}