.section-catalog{
	&_pg{
		border-top: 2px solid $white;
	}
}

.catalog__items{
	display: flex;
	align-items: stretch;
	//@media (max-width: $sw-md-max) {
		flex-wrap: wrap;
	//}
	@media (max-width: $sw-xs-max) {
		flex-direction: column;
	}
}

.catalog__item{
	position: relative;
	margin-bottom: 2px;
	width: 50%;
	@media (max-width: $sw-xs-max) {
		width: 100%;
	}
	&:nth-child(odd):last-child {
		width: 100%;
	}
	&:not(:first-child){
		&:after{
			top: 0;
			bottom: 0;
			width: 2px;
			left: -1px;
			content: '';
			display: block;
			position: absolute;
			background-color: $white;
			@media (max-width: $sw-xs-max) {
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 2px;
				bottom: auto;
			}
		}
	}
}

.catalog-box{
	display: flex;
	position: relative;
	//padding-top: 7.8rem;
	align-items: stretch;
	height: 100%;
	//padding-left: 3.2rem;
	//padding-bottom: 8rem;
	//padding-right: 3.2rem;
	background-color: $athensGray;
	//@media (max-width: $sw-md-max) {
		padding: 1.6rem;
		padding-top: 6rem;
		padding-bottom: 6.9rem;
	//}
	@media (max-width: $sw-sm-max) {
		padding-bottom: 5rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-top: 5.9rem;
		padding-bottom: 16.4rem;
	}
	@media (max-width: $sw-xx-max) {
		padding-bottom: 12.4rem;
	}
	@media (min-width: $sw-lg-min) {
		&_lg{
			padding-top: 7.8rem;
			padding-left: 3.2rem;
			padding-right: 3.2rem;
			padding-bottom: 6.2rem;
			.catalog-box__view{
				width: 52%;
				min-height: 42rem;
				@media (max-width: 1568px) {
					width: 50%;
				}
			}
			.catalog-box__detail{
				width: 48%;
				padding-top: 4rem;
				padding-left: 3.6rem;
				@media (max-width: 1568px) {
					width: 50%;
				}
			}
			.catalog-box__name{
				margin-top: 6.8rem;
			}
			.catalog-box__description{
				margin-top: 2.5rem;
			}
			.catalog-box__price {
				margin-top: 2.4rem;
			}
			.catalog-box__actions{
				margin-top: 5.7rem;
			}
		}
	}
}

.catalog-box__view{
	width: 40%;
	flex-shrink: 0;
	@media (max-width: 1568px) {
		width: 24%;
	}
	//@media (max-width: $sw-md-max) {
		width: 40%;
	//}
	@media (max-width: $sw-sm-max) {
		width: 41.4%;
	}
	@media (max-width: $sw-xs-max) {
		width: 42.5%;
	}
	@media (max-width: $sw-xx-max) {
		width: 40%;
	}
	picture{
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		img{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			position: absolute;
		}
	}	
}

.catalog-box__detail{
	width: 60%;
	padding-left: 1.6rem;
	@media (max-width: 1568px) {
		width: 76%;
		padding-left: 2.4rem;
	}
	//@media (max-width: $sw-md-max) {
		width: 60%;
	//}
	@media (max-width: $sw-sm-max) {
		width: 58.6%;
	}
	@media (max-width: $sw-xs-max) {
		width: 57.5%;
	}
	@media (max-width: $sw-xx-max) {
		width: 60%;
		padding-left: 1.6rem;
	}
}

.catalog-box__main-details{
	line-height: 1;
	font-weight: 900;
	font-size: 1.8rem;
	color: $cornflowerBlue;
	letter-spacing: .05rem;
	text-transform: uppercase;
	.rouble{
		font-weight: 700;
	}
	//@media (max-width: $sw-md-max) {
		font-size: 2.6rem;
	//}
	@media (max-width: $sw-xx-max) {
		font-size: 2rem;
	}
	a {
		color: $cornflowerBlue;
		text-decoration: none;
	}
}

.catalog-box__name{
	font-size: 1.2rem;
	margin-top: 2.5rem;
	line-height: 1.8rem;
	letter-spacing: .1rem;
	text-transform: uppercase;
	@media (max-width: $sw-xs-max) {
		margin-top: 2.4rem;
	}
	@media (max-width: $sw-xx-max) {
		font-size: 1.1rem;
	}
}

.catalog-box__description{
	font-size: 1.3rem;
	margin-top: 1.1rem;
	@media (max-width: $sw-xx-max) {
		font-size: 1.2rem;
	}
}

.catalog-box__price{
	margin-top: 4rem;
	//@media (max-width: $sw-md-max) {
		font-size: 2.4rem;
		margin-top: 2.4rem;
	//}
	@media (max-width: $sw-xs-max) {
		font-size: 3.8rem;
		margin-top: 1.4rem;
	}
	@media (max-width: $sw-xx-max) {
		font-size: 2.4rem;
	}
}

.catalog-box__priceBaseEd{
	margin-top: 0.3rem;
    font-size: 1.2rem;
    color:#3e3e48;
    text-transform: lowercase;
	@media (max-width: $sw-xs-max) {
		font-size: 1.5rem;
	}
	@media (max-width: $sw-xx-max) {
		font-size: 1.3rem;
	}
}

.catalog-box__actions{
	display: flex;
	flex-wrap: wrap;
	margin-top: 1.8rem;
	align-items: center;
	//@media (max-width: $sw-md-max) {
		margin-top: 3.5rem;
	//}
	@media (max-width: $sw-sm-max) {
		flex-direction: column;
		align-items: flex-start;
	}
	@media (max-width: $sw-xs-max) {
		left: 0;
		right: 0;
		margin-top: 0;
		bottom: 3.9rem;
		position: absolute;
		flex-direction: row;
		justify-content: center;
	}
}

.catalog-box__action{
	a, button{
		min-width: 12rem;
		@media (max-width: $sw-xs-max) {
			height: 5.8rem;
			font-size: 1.4rem;
			min-width: 17.4rem;
			line-height: 5.8rem;
			letter-spacing: .2rem;
		}
		@media (max-width: $sw-xx-max) {
			height: 4.2rem;
			min-width: 13rem;
			font-size: 1.2rem;
			letter-spacing: 0;
			line-height: 4.2rem;
		}
	}
	&:not(:first-child){
		margin-left: 1rem;
		@media (max-width: $sw-sm-max) {
			margin-left: 0;
			margin-top: .8rem;
		}
		@media (max-width: $sw-xs-max) {
			margin-top: 0;
			margin-left: 1.6rem;
		}
		@media (max-width: $sw-xx-max) {
			margin-top: 0;
			margin-left: .8rem;
		}
	}
}
