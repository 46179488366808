.cartel-promo {
  background: url('/s/img/cartel/tattoorevive-cartel.jpg') no-repeat center center;
  background-size: cover;
  width: 100%;
  aspect-ratio: 16 / 10;
  position: relative;
  overflow: hidden;
}

.cartel-promo .text {
  font-size: 7rem;
  line-height: 9rem;
  color: #ffffff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
}

.cartel-text {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: bold;
  margin-top: 50px;
}

.cartel-conditions {
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin-top: 25px;
}

.cartel-conditions li {
  margin-top: 20px;
}

.cartel-text-small {
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-weight: normal;
  margin-top: 40px;
}

@media (max-width: 750px) {
  .cartel-promo .text {
    font-size: 6rem;
    line-height: 7rem;
  }
}

@media (max-width: 600px) {
  .cartel-promo .text {
    font-size: 5rem;
    line-height: 6rem;
  }
}

@media (max-width: 480px) {
  .cartel-promo .text {
    font-size: 3rem;
    line-height: 4rem;
  }
}