button{
    // button reset
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    outline: none !important;
    background-color: transparent;
}
.btn{
    height: 4rem;
    border: none;
    color: $white;
    font-size: 1rem;
    font-weight: 400;    
    overflow: hidden;
    border-radius: 0;
    padding: 0 1.6rem;
    line-height: 4rem;
    position: relative;
    text-decoration: none;
    display: inline-block;
    letter-spacing: .15rem;
    text-transform: uppercase;
    transition: all 300ms ease;
    background-color: $charade;
    &:disabled{
        opacity: 1;
    }
    &:after{
        opacity: 0;
        top: -.1rem;
        content: '';
        left: -.1rem;
        right: -.1rem;
        bottom: -.1rem;
        display: block;
        visibility: hidden;
        position: absolute;
        transition: all 0.3s ease;
        border: .1rem solid $white;
    }
    &:hover{
        color: $white;
    }
    @media (min-width: $sw-md-min) {
        &:not(:disabled):hover{
            text-decoration: none;
            background-color: $charade;
            &:after{
                top: .3rem;
                opacity: 1;
                left: .3rem;
                content: '';
                right: .3rem;
                bottom: .3rem;
                visibility: visible;
            }
        }
    }

    &:focus,
    &:active{
    }

    // colors
    &_gr{
        background-color: $frenchGray;
        @media (min-width: $sw-md-min) {
            &:not(:disabled):hover{
                background-color: $frenchGray;
            }
        }
    }
    &_tr{
        color: $stormGray;
        line-height: 3.8rem;
        border: 2px solid $stormGray;
        background-color: transparent;
        &:after{
            display: none;
        }
        &:hover{
            color: $white;
        }
        @media (min-width: $sw-md-min) {
            &:not(:disabled):hover{
                background-color: $stormGray;
            }
        }
        &-gr{
            color: $frenchGray;
            border: 2px solid $frenchGray;
            &:hover{
                color: $white;
            }
            @media (min-width: $sw-md-min) {
                &:not(:disabled):hover{
                    background-color: $frenchGray;
                }
            }
        }
    }
    
    // sizes
    &_lg{
    }
    &_md{
    }
    &_sm{
    }
    &_xs{
    } 
    &_fl{
        width: 100%;
    }

}