.s-order-history {
	border-top: 1px solid #f2f2f7;
	padding-bottom: 6rem;
	&__wrapper {
		width: 100%;
		max-width: 640px;
		margin: 0 auto;
	}
	&__head {
		display: flex;
		justify-content: flex-start;
		padding: 0 0 0 3rem;
		@media (max-width: $sw-sm-max) {
			display: none;
		}
		&-number, 	&-create, 	&-price, 	&-status {
			button {
				color: #b2b2c3;
				font-size: 10px;
				font-weight: 400;
				text-transform: uppercase;
				letter-spacing: 2px;
				padding-right: 25px;
				position: relative;
				&:hover {
					&:after {
						border-color: #4d4d57 transparent transparent transparent;
					}
				}
				&:after {
					content: '';
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 0;
					height: 0;
					border-style: solid;

					transition: all 0.3s ease;
				}
			}
		}
		&-number {
			width: 100%;
			max-width: 19%;
		}
		&-create {
			width: 100%;
			max-width: 34%;
		}
		&-price {
			width: 100%;
			max-width: 32%;
		}
		&-status {
			width: 100%;
			max-width: 18%;
		}

		&-number, &-create, &-price, &-status {
			&.direction-asc {
				button {
					&:after {
						border-width: 0 5px 5px 5px;
						border-color: transparent transparent #dfdfe9 transparent;
					}
				}
				&.active {
					button {
						&:after {
							border-width: 0 5px 5px 5px;
							border-color: transparent transparent #4d4d57 transparent;
						}
					}
				}
			}
			&.direction-desc {
				button {
					&:after {
						border-width: 5px 5px 0 5px;
						border-color: #dfdfe9 transparent transparent transparent;
					}
				}
				&.active {
					button {
						&:after {
							border-width: 5px 5px 0 5px;
							border-color: #4d4d57 transparent transparent transparent;
						}
					}
				}
			}

		}

		&-number.direction-asc, &-create.direction-asc, &-price.direction-asc, &-status.direction-asc {
			//button {
			//	&:after {
			//		border-width: 0 5px 5px 5px;
			//		border-color: transparent transparent #dfdfe9 transparent;
			//	}
			//}
		}
		&-number.active, 	&-create.active, 	&-price.active, 	&-status.active {
			button {
				&:after {
					border-width: 0 5px 5px 5px;
					border-color: transparent transparent #4d4d57 transparent;
				}
			}
		}
	}
	&__body {
		padding-top: 1.4rem;
	}
	&__message {
		text-align: center;
	}
	&__item {
		background-color: #f9f9fd;
		margin: 2px 0;
		display: flex;
		justify-content: flex-start;
		padding: 18px 27px 13px 30px;
		transition: all 0.3s ease;
		cursor: pointer;
		@media (max-width: $sw-sm-max) {
			flex-wrap: wrap;
			padding-top: 34px;
			padding-right: 18px;
			padding-right: 17px;
		}
		&:hover {
			background-color: #f2f2f7;;
		}
		&-number {
			max-width: 19%;
			width: 100%;
			@media (max-width: $sw-sm-max) {
				max-width: 50%;
			}
			a{
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
				span {
					color: #4d4d57;
					font-size: 12px;
					font-weight: 700;
					line-height: 18px;
					text-transform: uppercase;
					letter-spacing: 1.2px;
					@media (max-width: $sw-sm-max) {
						font-size: 2rem;
					}
				}
			}

		}
		&-data {
			max-width: 34.5%;
			width: 100%;
			
			@media (max-width: $sw-sm-max) {
				max-width: 50%;
				text-align: right;
						}
			a{
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			.data {
				color: #4d4d57;
				font-size: 12px;
				font-weight: 400;
				text-transform: uppercase;
				@media (max-width: $sw-sm-max) {
					font-size: 2rem;
				}
			}
			.time {
				color: #b2b2c3;
				font-size: 10px;
				letter-spacing: 1px;
				margin-left: 5px;
				@media (max-width: $sw-sm-max) {
					font-size: 2rem;
				}
			}
		}
		&-price {
			max-width: 26%;
			width: 100%;
			@media (max-width: $sw-sm-max) {
				max-width: 50%;
				margin-top: 16px;
			}
			@media (max-width: $sw-xx-max) {
				max-width: 100%;
			}
			b {
				color: #4d4d57;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 1.2px;
				line-height: 18px;
				@media (max-width: $sw-sm-max) {
					font-size: 2.8rem;
				}
			}
			span{
				color: #b2b2c3;
				font-size: 10px;
				font-weight: 400;
				letter-spacing: 2px;
				margin-left: 5px;
				@media (max-width: $sw-sm-max) {
					font-size: 2.8rem;
				}
			}
		}
		&-status {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			max-width: 118px;
			width: 100%;
			@media (max-width: $sw-sm-max) {
				max-width: 50%;
				margin-top: 2rem;
			}
			@media (max-width: $sw-xx-max) {
				max-width: 100%;
			}
			span {
				color: #b2b2c3;
				font-size: 10px;
				font-weight: 300;
				text-transform: uppercase;
				letter-spacing: 1px;
				white-space: nowrap;
				@media (max-width: $sw-sm-max) {
					font-size: 2rem;
				}
			}
			a {
				color: #2b2b38;
				font-size: 10px;
				font-weight: 400;
				letter-spacing: 1px;
				text-decoration: underline;
				text-transform: uppercase;
				@media (max-width: $sw-sm-max) {
					font-size: 2rem;
				}
			}
			.red {
				color: #f26f60;
				white-space: nowrap;
				@media (max-width: $sw-xx-max) {
					white-space: pre-wrap;
					text-align: right;
				}
			}
			.yellow {
				color: #ffcc08;
				white-space: nowrap;
				@media (max-width: $sw-xx-max) {
					white-space: pre-wrap;
					text-align: right;
				}
			}
			.green {
				color: #43b77a;
				white-space: nowrap;
				@media (max-width: $sw-xx-max) {
					white-space: pre-wrap;
					text-align: right;
				}
			}
			.cancel {
				color: #4d4d57;
				white-space: nowrap;
				@media (max-width: $sw-xx-max) {
					white-space: pre-wrap;
					text-align: right;
				}
			}
		}
	}
}
.order-pagination {
	padding-top: 8rem;
	padding-bottom: 5rem;
	@media (max-width: $sw-md-max) {
		padding-bottom: 0;
		margin-bottom: -2rem;
	}
	@media (max-width: $sw-sm-max) {
		padding-bottom: 0;
		margin-bottom: -11rem;
	}
}

.s-invoice__ready-block .red, .s-invoice__ready-block-m .red {
	color: #f26f60;
	white-space: nowrap; }
.s-invoice__ready-block .yellow, .s-invoice__ready-block-m .yellow {
	color: #ffcc08;
	white-space: nowrap; }
.s-invoice__ready-block .green, .s-invoice__ready-block-m .green {
	color: #43b77a;
	white-space: nowrap; }
