.section-custom-nav {
  padding-top: 4rem;
  padding-bottom: 3.8rem;
  background-color: $athensGray;
  @media (max-width: $sw-xs-max) {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
  }
  &.white {
    background-color: $white;
    padding-top: 0;
  }
}

.custom-nav__items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: $sw-xs-max) {
    flex-direction: column;
  }
}

.custom-nav__item {
  a {
    color: #b2b2c3;
    display: block;
    padding-top: 1rem;
    font-size: 1.3rem;
    padding-left: 2.7rem;
    text-decoration: none;
    border-radius: 2.7rem;
    padding-right: 2.7rem;
    padding-bottom: 0.8rem;
    letter-spacing: .05rem;
    text-transform: uppercase;
    border: 2px solid transparent;
    @media (max-width: $sw-xs-max) {
      font-size: 1.7rem;
      padding-top: 1.2rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      letter-spacing: .1rem;
      padding-bottom: 1.3rem;
    }
  }

  &.active {
    a {
      color: $stormGray;
      border: 2px solid $stormGray;
    }
  }

  &:not(.active) {
    @media (min-width: $sw-md-min) {
      a {
        &:hover {
          color: $black;
        }
      }
    }
  }

  &:not(:last-child) {
    @media (max-width: $sw-xs-max) {
      margin-bottom: .8rem;
    }
  }
}
