.p-wrapp{
	display: flex;
	overflow: hidden;
	min-height: 100vh;
	position: relative;
	flex-direction: column;
}

main{
	flex-grow: 1;
	&.main_df{
		display: flex;
	}
}

img {
	max-width: 100%;
	height: auto;
	width: auto;
}

a {
	outline: none;
	transition: color $transition_duration ease;
	color: $stormGray;

	&.no-decoration{
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
		&:focus {
			text-decoration: none;
		}
		&:active {
			text-decoration: none;
		}
	}

	&.link{
		&_dk{
			color: $charade;
			@media (min-width: $sw-md-min) {
				&:hover{
					color: #3e6ce3;
				}
			}
		}
		&_gr{
			color: $stormGray;
			@media (min-width: $sw-md-min) {
				&:hover{
					color: $black;
				}
			}
		}
		&_is{
			font-size: 1rem;
			letter-spacing: .2rem;
			text-transform: uppercase;
			@media (max-width: $sw-xs-max) {
				font-size: 2rem;
			}
		}
		&_under{
			color: $charade;
			overflow: hidden;
			position: relative;
			display: inline-block;
			&:after{
				left: 0;
				right: 0;
				bottom: 0;
				height: 1px;
				content: '';
				display: block;
				position: absolute;
				background-color: $charade;
				transition: all 0.375s ease;
				transform: translateX(-110%);
			}
			@media (min-width: $sw-md-min) {
				&:hover{
					color: $black;
					&:after{
						transform: translateX(0);
					}
				}
			}
		}










		&_txt{
			color: #000;
			@media (min-width: $sw-md-min) {
				&:hover{
					color: #3e6ce3;
				}
			}
		}
		&_blu{
			color: #3e6ce3;
			@media (min-width: $sw-md-min) {
				&:hover{
					color: #000;
				}
			}
		}
		&_red{
			color: #E4022A;
			@media (min-width: $sw-md-min) {
				&:hover{
					color: #F5A623;
				}
			}
		}
		&_gray{
			color: #9B9B9B;
			@media (min-width: $sw-md-min) {
				&:hover{
					color: #000000;
				}
			}
		}
	}

	&:hover {
		text-decoration: underline;
		outline: none;
	}
	&:focus {
		text-decoration: underline;
		outline: none;
	}
	&:active {
		text-decoration: underline;
		outline: none;
	}
}

.row-flex,
.col-flex,
.d-flex {
	display: flex;
	flex-wrap: wrap;
}

body {
	&.scroll-disabled {
		max-height: 100vh;
		overflow: hidden;
		position: fixed;
		width: 100vw;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
	}
	&.body_vr{
		background-color: $athensGray;
	}
}

.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: top;
    display: inline-block;
}


h1,h2,h3,h4,h5,h6{
	margin: 0;
	padding: 0;
}

@mixin h1(){
	line-height: 1.3;
	font-weight: 900;
	font-size: 3.6rem;
	color: $cornflowerBlue;
	letter-spacing: .35rem;
	text-transform: uppercase;
	@media (max-width: $sw-xx-max) {
		font-size: 2.8rem;
		letter-spacing: 0.1rem;
	}
}

h1{
	@include h1();
	margin-bottom: 3.6rem;
}

@mixin h2(){
	line-height: 1.3;
	font-weight: 700;
	font-size: 1.4rem;
	color: $cornflowerBlue;
	letter-spacing: .12rem;
	text-transform: uppercase;
	@media (max-width: $sw-xx-max) {
		font-size: 1.3rem;
		letter-spacing: 0.1rem;
	}
}

h2{
	@include h2();
	margin-top: 4.2rem;
	margin-bottom: 2.4rem;

}

@mixin h3(){
	line-height: 1;
	font-weight: 900;
	font-size: 1.4rem;
	color: $cornflowerBlue;
	letter-spacing: .14rem;
	text-transform: uppercase;
}

h3{
	@include h3();
	margin-top: 3.6rem;
	margin-bottom: 1.8rem;
}

@mixin h4(){
	line-height: 1;
	font-weight: 400;
	font-size: 1.4rem;
	color: $cornflowerBlue;
	letter-spacing: .14rem;
	text-transform: uppercase;
	// @media (max-width: $sw-xx-max) {
	// 	font-size: 1.3rem;
	// 	letter-spacing: 0.1rem;
	// }
}

h4{
	@include h4();
	margin-top: 3.2rem;
	margin-bottom: 1.6rem;
}

h5{
	font-weight: 600;
	font-size: 1.2rem;
	margin-top: 1.6rem;
	line-height: 2.2rem;
	margin-bottom: 1.2rem;
	text-transform: uppercase;
}
h6{
	font-size: 1rem;
	font-weight: 600;
	margin-top: 1.6rem;
	line-height: 2.2rem;
	margin-bottom: 1.6rem;
	text-transform: uppercase;
}

.like-h1 {
	@include h1();
	margin-bottom: 3.6rem;
}

// UP BTN
.up-btn {
	display: inline-block;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: #1c1c1f;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	right: 85px;
	z-index: 30;
	bottom: 90px;
	transition: opacity .3s ease-in-out, visibility .3s ease-in-out, bottom .3s ease-in-out;
	opacity: 0;
	visibility: hidden;
	svg{
		fill: #fff;
		font-size: 2.4rem;
	}
	@media (max-width: $sw-md-max) {
		width: 60px;
		height: 60px;
		right: 25px;
		bottom: 70px;
	}
	@media (max-width: $sw-sm-max) {
		display: none;
	}
	&.visible {
		opacity: 1;
		visibility: visible;
	}
	&.sticky {
		bottom: 180px;
		@media (max-width: $sw-md-max) {
			bottom: 140px;
		}
	}
}


// CHECKBOX GLOBAL
.checkbox-label {
	@media (max-width: $sw-xs-max) {
		margin-top: 1.5rem;
	}
	.check-text, .check-text a {
		color: $cornflowerBlue;
		font-size: 1rem;
		font-weight: 300;
		line-height: 1.8rem;
		text-transform: uppercase;
		letter-spacing: .2rem;
	}
	.check-text {
		a {
			text-decoration: underline;
		}
	}
}



@mixin p(){
	font-size: 1.4rem;
	line-height: 2.4rem;
	letter-spacing: .05rem;
	color: $cornflowerBlue;
	@media (max-width: $sw-sm-max) {
		letter-spacing: 0;
		line-height: 1.8rem;
		margin-bottom: 1.8rem;
	}
}

p{
	@include p();
	margin-bottom: 2.4rem;
}

.box-title{
	color: #000;
	font-weight: 600;
	font-size: 1.8rem;
	line-height: 2.4rem;
	text-transform: uppercase;
}


table{
	width: 100%;
	font-size: 1.2rem;
	text-align: center;
	table-layout: fixed;
	line-height: 1.4rem;
	border: 1px solid #D2D2D2;
	td{
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}
	thead{
		color: #fff;
		background-color: #000;
		tr{
			td{
				width: 100%;
			}
		}
	}
	tbody{
		tr{
			&:nth-child(odd){
				background-color: #F3F3F3;
			}
		}
	}
}


.profile_head {
	display: flex;
	flex-direction: column;
}

// NEWWWWWWWWWWWWWWWWWWWWWWWW


.box-title{
	text-align: center;
	margin-top: 6.4rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	margin-bottom: 3.6rem;
	@media (max-width: $sw-md-max) {
		margin-top: 12.9rem;
		margin-bottom: 5.2rem;
	}
	@media (max-width: $sw-xs-max) {
		margin-top: 12.5rem;
		margin-bottom: 4.8rem;
	}
	&__name{
		@include h1();
		@media (max-width: $sw-xx-max) {
			font-size: 2.8rem;
			letter-spacing: 0.1rem;
		}
		&.light {
			color: #dfdfe9;
		}
	}
	&__caption{
		margin-top: .6rem;
	}
	&_xc{
		@media (max-width: $sw-xs-max) {
			margin-top: 8.5rem;
		}
	}
}



.container{
	width: 100%;
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
}



.video-box{
	margin-bottom: 3.2rem;
	&__content{
		height: 0;
		padding-top: 25px;
		position: relative;
		padding-bottom: 56.25%;
	}
	iframe{
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}
}

hr{
	margin-top: 1.8rem;
	margin-bottom: 1.8rem;
	border: 0;
	height: 1px;
	position: relative;
	&:after{
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		height: 1px;
		display: block;
		position: absolute;
		background: linear-gradient(to right, #c3c2d1 5px, transparent 5px, transparent 6px);
		background-size: 10px 1px;
		transition: all 0.3s ease;
		background-repeat: repeat-x;
		background-position: center bottom;
	}
}

.double-col-box{
	display: flex;
	align-items: flex-start;
	@media (max-width: $sw-xs-max) {
		flex-wrap: wrap;
	}
}

.double-col-box__cl{
	width: 50%;
	@media (max-width: $sw-xs-max) {
		width: 100%;
		&:not(:last-child){
			margin-bottom: .5rem;
		}
	}
}

.double-col-box__item{
	&:not(:last-child){
		margin-bottom: .5rem;
	}
}



.collapse-box{
	.collapse-box__input{
		display: none;
	}
	.collapse-box__button{
		margin: 0;
		cursor: pointer;
	}
	.collapse-box__content{
		max-height: 0;
		overflow: hidden;
		transition: all 0.5s ease;
	}
	input:checked + .collapse-box__button{
		// background-color: #000;
	}
	input:checked + .collapse-box__button + .collapse-box__content{
		// display: block;
	}
}





// .animate-section{
// 	animation-duration: 1s;
// 	animation-play-state: paused;
// 	animation-fill-mode: backwards;
// 	animation-timing-function: ease-in;
// 	&:nth-child(odd){
// 		animation-name: fadeInLeft;
// 	}
// 	&:nth-child(even){
// 		animation-name: fadeInRight;
// 	}
// 	&.visible{
// 		animation-play-state: running;
// 	}
// }


// @keyframes fadeInLeft {
// 	from {
// 		opacity: 0;
// 		transform: translate3d(-100%, 0, 0);
// 	}
// 	to {
// 		opacity: 1;
// 		transform: translate3d(0, 0, 0);
// 	}
// }

// @keyframes fadeInRight {
// 	from {
// 		opacity: 0;
// 		transform: translate3d(100%, 0, 0);
// 	}
// 	to {
// 		opacity: 1;
// 		transform: translate3d(0, 0, 0);
// 	}
// }


.modal-page-content {
	padding: 5rem 3rem;
	text-align: center;
}
.catalog-box__action-left {
	margin-right: 2rem;
	@media (max-width: $sw-sm-max) {
		margin-right:0;
	}
}

.bold {
	font-weight: bold;
}
