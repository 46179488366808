.text-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.plainText.unsubscribe {
    text-align: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    font-size: 2.5rem;
    font-weight: bold;
    color: #009933;
}
