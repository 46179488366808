body{
	@media (max-width: $sw-sm-max) {
		&.nav-active{
			.header__nav{
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
			.header__menu{
				display: none;
				@media (max-width: $sw-sm-max) {
					display: block;
				}
				button{
					svg{
						font-size: 4rem;
						.icon-menu-open{
							display: none;
						}
						.icon-menu-close{
							display: block;
						}
					}
				}
			}
		}
	}
}

header{
	padding-top: 6rem;
	padding-left: 8rem;
	position: relative;
	padding-right: 8rem;
	padding-bottom: 6rem;
	background-color: $white;
	border-bottom: 1px solid $charade;
	@media (max-width: $sw-md-max) {
		padding-left: 5rem;
		padding-top: 3.4rem;
		padding-right: 5rem;
		padding-bottom: 3.6rem;
	}
	@media (max-width: $sw-sm-max + 150px) {
		padding-right: 3rem;
	}

	@media (max-width: $sw-sm-max) {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
	@media (max-width: $sw-xx-max) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}
}

.header__wrapper{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__menu{
	display: none;
	@media (max-width: $sw-sm-max) {
		display: block;
	}
	button{
		svg{
			font-size: 4rem;
			.icon-menu-close{
				display: none;
			}
		}
	}
}

.header__logo{
	flex-shrink: 0;
	margin-right: 8.2rem;
	@media (max-width: $sw-md-max) {
		left: 57%;
	}
	@media (max-width: $sw-sm-max) {
		top: 50%;
		left: 50%;
		margin-right: 0;
		position: absolute;
		transform: translate(-50%, -50%);
	}
	@media (max-width: $sw-xx-max) {
		top: auto;
		left: auto;
		width: 100%;
		flex-shrink: 1;
		transform: none;
		position: relative;
		margin-left: 2.4rem;
	}
	a{
		display: block;
		svg{
			fill: $charade;
			font-size: 6rem;
			transition: fill 0.3s ease;
			@media (max-width: $sw-xx-max) {
				font-size: 3.2rem;
			}
		}
		@media (min-width: $sw-md-min) {
			&:hover{
				svg{
					fill: #000000;
				}
			}
		}
	}
}

.header__nav{
	top: 50%;
	left: 50%;
	@media (max-width: $sw-md-max) {
		left: 50%;
	}
	margin-top: .2rem;
	position: absolute;
	transform: translate(-50%, -50%);
	@media (max-width: $sw-md-max) {
		padding-left: 3rem;
	}
	@media (max-width: $sw-sm-max) {
		left: 0;
		right: 0;
		top: 100%;
		opacity: 0;
		z-index: 11;
		margin-top: 0;
		transform: none;
		margin-top: .1rem;
		visibility: hidden;
		padding-left: 5rem;
		padding-top: 5.1rem;
		padding-right: 5rem;
		padding-bottom: 5.6rem;
		background-color: $white;
		transition: all 0.3s ease;
		transform: translateY(5%);
	}
	@media (max-width: $sw-xs-max) {
		padding-top: 4.3rem;
		padding-left: 4.2rem;
		padding-right: 4.2rem;
		padding-bottom: 3.9rem;
	}
	@media (max-width: $sw-xx-max) {
		padding-top: 3.2rem;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		padding-bottom: 3.2rem;
	}
}

.header__nav-items{
	display: flex;
	margin-top: .6rem;
	align-items: center;
	@media (max-width: $sw-sm-max) {
		margin-top: 0;
		justify-content: space-between;
	}
	@media (max-width: $sw-xs-max) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.header__nav-item{
	&:not(:last-child){
		margin-right: 5.2rem;
		@media (max-width: 1568px) {
			margin-right: 4.2rem;
		}
		@media (max-width: $sw-md-max) {
			margin-right: 3.6rem;
		}
		@media (max-width: $sw-sm-max + 100px) {
			margin-right: 2rem;
		}
		@media (max-width: $sw-sm-max) {
			margin-right: 2.4rem;
		}
		@media (max-width: $sw-xs-max) {
			margin-right: 0;
			margin-bottom: 3.2rem;
		}
		@media (max-width: $sw-xx-max) {
			margin-bottom: 1.6rem;
		}
	}
	a{
		font-weight: 700;
		font-size: 1.6rem;
		white-space: nowrap;
		text-transform: uppercase;
		@media (max-width: 1568px) {
			font-size: 1.5rem;
		}
		@media (max-width: $sw-md-max) {
			font-size: 1.3rem;
		}
		@media (max-width: $sw-sm-max) {
			font-size: 1.5rem;
		}
		@media (max-width: $sw-xs-max) {
			font-weight: 400;
			font-size: 2.4rem;
			letter-spacing: .2rem;
		}
		@media (max-width: $sw-xx-max) {
			font-size: 2rem;
		}
	}
}

.header__actions{
	display: flex;
	flex-shrink: 0;
	align-items: center;
}

.header__action{
	&:not(:last-child){
		margin-right: 1.6rem;
		@media (max-width: $sw-xx-max) {
			margin-right: .8rem;
		}
	}
	a{
		width: 4rem;
		height: 4rem;
		display: block;
		position: relative;
		border-radius: 50%;
		background-color: $white;
		border: 1px solid $charade;
		svg{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			fill: $charade;
			position: absolute;
			&.icon-cart{
				font-size: 1.5rem;
			}
			&.icon-open{
				font-size: 1.8rem;
			}
		}
		span{
			top: -.4rem;
			right: -.4rem;
			color: $white;
			width: 2.3rem;
			height: 2.3rem;
			font-size: 1.1rem;
			position: absolute;
			text-align: center;
			border-radius: 50%;
			line-height: 2.3rem;
			background-color: $charade;
		}
	}
	&_rv{
		a{
			background-color: $charade;
			svg{
				fill: $white;
			}
		}
	}
}
