.s-settings {
	border-top: 1px solid #f2f2f7;
	padding-bottom: 6rem;
	padding-top: 11rem;
	&-two {
		border-top: 1px solid #f2f2f7;
		border-bottom: 1px solid #f2f2f7;
		padding-top: 6rem;
		padding-bottom: 5rem;
		@media (max-width: $sw-sm-max) {
			padding-top: 8rem;
	}
	.s-settings__item-forms {
		@media (max-width: $sw-sm-max) {
			padding-top: 9.7rem;
	}
	}
	}
	&-third {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	&__wrapper {
		width: 100%;
		max-width: 650px;
		margin: 0 auto;
	}
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@media (max-width: $sw-sm-max) {
			flex-direction: column;
			align-items: center;
		}
		&-title {
			h3 {
				margin: 0;
				color: #2b2b38;
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 1.4px;
				@media (max-width: $sw-sm-max) {
					font-size: 18px;
			}
			}
		}
		&-label {
			padding-left: 32px;
			margin-top: -4px;
			span {
				color: #3e3e48;
				font-size: 10px;
				font-weight: 300;
				line-height: 18px;
				text-transform: uppercase;
				letter-spacing: 2px;
				@media (max-width: $sw-sm-max) {
					font-size: 14px;
				}
			}
		}
		&-checkbox {
			margin-bottom: 3.3rem;
			@media (max-width: $sw-xx-max) {
				margin-bottom: 1.8rem;
			}
			&-disabled {
				margin-bottom: 0;
				.s-settings__item-label span {
					color: #b2b2c3;
				}
			}
			.checkbox-label {
				@media (max-width: $sw-xs-max) {
					margin-top: 0;
				}
			}
		}
		&-form {
			.form-control {
				background-color: #fff;
				border: none;
				border-radius: 0;
				box-shadow: none !important;
				border-bottom: 1px solid #b2b2c3;
				padding: 0;
				text-align: left;
				color: #b2b2c3;
				font-size: 12px;
				font-weight: 300;
				line-height: 25px;
				text-transform: uppercase;
				letter-spacing: 1.2px;
				height: 2rem !important;
				min-width: 32rem;
				margin-bottom: 3rem;
				@media (max-width: $sw-sm-max) {
					height: 5rem !important;
			}
			@media (max-width: $sw-xx-max) {
				min-width: 31rem;
			}
			}
		}
		&-two {
			margin-bottom: 2rem;
			.s-settings__item-title {
				margin-top: 0;
			}
		}
		&-forms {
			@media (max-width: $sw-sm-max) {
				padding-top: 7.7rem;
				width: 90%;
				display: flex;
    			justify-content: center;
			}
		}
	}
	&__button {
		display: flex;
		justify-content: center;
		button {
			padding: 0 3.6rem;
			@media (max-width: $sw-sm-max) {
				font-size: 22px;
				padding: 0 6.6rem;
				height: 7rem;
		}
		}
	}
}