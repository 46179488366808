footer {
  //margin-top: 5.6rem;
  padding-top: 6.5rem;
  padding-bottom: 6rem;
  background-color: #1c1c1f;
  @media (max-width: $sw-md-max) {
    //margin-top: 10.3rem;
    padding-top: 4.3rem;
    padding-bottom: 4.3rem;
  }
  @media (max-width: $sw-sm-max) {
    padding-top: 3rem;
    //margin-top: 11.3rem;
    padding-bottom: 3rem;
  }
  @media (max-width: $sw-xs-max) {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }

  &.contacts-footer {
    margin-top: 0;
  }
}

.footer {
  &__menu {
    width: 100%;
    padding-bottom: 3rem;
    @media (max-width: $sw-md-min) {
      text-align: center;
      padding-bottom: 0;
      margin-bottom: 30px;
    }
  }

  &__menu a {
    white-space: nowrap;
  }

  &__menu-link {
    color: #6f6f7b;
    font-size: 1.2rem;
    @media (max-width: $sw-md-min) {
      font-size: 1.5rem;
      line-height: 200%;
    }

    &:hover {
      color: #aeaeae;
      text-decoration: underline;
    }

    text-transform: uppercase;
    text-decoration: none;

    &:not(:last-child):after {
      content: '';
      margin: 0 7px;
    }
  }
}

.footer__wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 138rem;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  padding-left: 5rem;
  align-items: center;
  padding-right: 5rem;
  justify-content: space-between;
  @media (max-width: $sw-xs-max) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    flex-direction: column;
  }
}

.footer__company {
  display: flex;
  align-items: center;
  padding-right: 1.6rem;
  @media (max-width: $sw-sm-max) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: $sw-xs-max) {
    order: 1;
    margin-top: 4.6rem;
    align-items: center;
    padding-right: 0;
  }
}

.footer__logo {
  a {
    display: block;

    svg {
      font-size: 4rem;
      fill: #6f6f7b;
      transition: fill 0.3s ease;
    }

    @media (min-width: $sw-md-min) {
      &:hover {
        svg {
          fill: #aeaeae;
        }
      }
    }
  }

  & + div {
    margin-left: 5rem;
    @media (max-width: $sw-md-max) {
      margin-left: 3.2rem;
    }
    @media (max-width: $sw-sm-max) {
      margin-left: 0;
      margin-top: 1.5rem;
    }
  }
}

.footer__copywriting {
  font-size: 1rem;
  color: #6f6f7b;
  letter-spacing: .2rem;
  text-transform: uppercase;
  @media (max-width: $sw-xs-max) {
    text-align: center;
  }
}

.footer__setting {
  display: flex;
  flex-shrink: 0;
  @media (max-width: $sw-xs-max) {
    align-items: center;
    flex-direction: column;
  }
  .footer__social{
    display: flex;
    align-items: center;
    flex-direction: column;
    .social-box{
      @media (max-width: $sw-sm-max) {
        margin-top: 30px;
      }
    }
  }
  .revive-dropdown {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    @media (max-width: $sw-sm-max) {
      top: 53px;
    }
    @media (min-width: $sw-sm-min) and (max-width: $sw-md-max){
      top: 20px;
      margin-bottom: 0;
    }
    div.lang{
      left: -85px;

      @media (min-width: $sw-md-min) {
        left: -70px;
      }
    }
    div.lang, div.cur {
      text-decoration: none;
      text-transform: uppercase;
      padding: 0 5px;
      text-align: left;
      color: #6f6f7b;
      font-size: 1.2rem;
      line-height: 2.5rem;
      display: block;
      cursor: pointer;
      background-color: inherit;
      position: absolute;
      width: 85px;
      top: -50px;
      @media (max-width: $sw-sm-max) {
        font-size: 1.5rem;
      }

      div {
        transition: color 0.3s ease;
        &:hover {
          text-decoration: none;
          background: inherit;
          color: #aeaeae;
        }

        &.selected:hover {
          background-color: transparent;
          color: #aeaeae;
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  .revive-dropdown div .lightblue {
    color: #6f6f7b;
    margin-left: -4px;
  }

  .show-sel {
    background-color: #1c1c1f!important;
  }

  .arrow-down-close {
    display: inline-block;
    width: 20px;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      height: 10px;
      width: 1px;
      background-color: #6f6f7b;
      position: relative;
      transition: 200ms ease-in-out transform;
    }

    &:before {
      transform: rotate(-45deg);
      left: 10px;
    }

    &:after {
      transform: rotate(45deg);
      left: 16px;
    }

    &.open {
      &:before {
        transform: translateX(4px) rotate(-45deg);
      }

      &:after {
        transform: translateX(-3px) rotate(45deg);
      }
    }
  }
}

.footer__location {
  @media (max-width: $sw-xs-max) {
    order: 1;
  }

  & + div {
    margin-left: 5rem;
    @media (max-width: $sw-md-max) {
      margin-left: 3.2rem;
    }
    @media (max-width: $sw-xs-max) {
      margin-left: 0;
      margin-bottom: 3.7rem;
    }
  }
}

.location-box__items {
  display: flex;
  overflow: hidden;
  border-radius: 3px;
  align-items: center;
  border: 1px solid #6f6f7b;
}

.location-box__item {
  position: relative;

  &:not(:first-child) {
    &:after {
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      content: '';
      display: block;
      position: absolute;
      background-color: #6f6f7b;
      @media (max-width: $sw-xs-max) {
        top: .4rem;
        bottom: .4rem;
      }
    }
  }

  a {
    opacity: .5;
    display: flex;
    color: #aeaeae;
    font-size: 1.2rem;
    padding-top: .6rem;
    padding-left: .8rem;
    align-items: center;
    padding-right: .8rem;
    padding-bottom: .6rem;
    transition: opacity .3s ease;
    @media (max-width: $sw-xs-max) {
      font-size: 1.6rem;
      padding-top: 1rem;
      padding-left: 1.1rem;
      padding-right: 1.1rem;
      padding-bottom: 1.1rem;
    }

    img {
      & + span {
        margin-left: .8rem;
      }
    }

    @media (min-width: $sw-md-min) {
      &:hover {
        opacity: 1;
      }
    }
  }
}

.social-box_team {
  padding: 20px 0 20px 10px;
}

.social-box__items {
  display: flex;
  align-items: center;
}

.social-box__item {
  &:not(:last-child) {
    margin-right: 1.8rem;
    @media (max-width: $sw-md-max) {
      margin-right: .8rem;
    }
    @media (max-width: $sw-xs-max) {
      margin-right: 1.8rem;
    }
  }

  a {
    width: 3.2rem;
    height: 3.2rem;
    display: block;
    position: relative;
    border-radius: 50%;
    border: 2px solid #6f6f7b;
    transition: border 0.3s ease;
    @media (max-width: $sw-xs-max) {
      width: 5rem;
      height: 5rem;
    }

    svg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      fill: #6f6f7b;
      position: absolute;
      transition: fill 0.3s ease;
      @media (max-width: $sw-xs-max) {
        font-size: 2rem;
      }
    }

    @media (min-width: $sw-md-min) {
      &:hover {
        border: 2px solid #aeaeae;

        svg {
          fill: #aeaeae;
        }
      }
    }
  }
}
