.s-pagination {
	max-width: 50%;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	padding-bottom: 5.5rem;
	@media (max-width: $sw-md-max) {
		padding-bottom: 10.2rem;
	}
	.pagination {
		display: flex;
		align-items: center;
		margin-left: -12px;
		@media (max-width: $sw-xx-max) {
			margin-left: 0;
		}
	}
}
.page {
	&-item{
		padding: 0;
		&-first {
			margin-left: 45px;
		}
		&-last {
			margin-right: 45px;
		}
	}
	&-link {
		font-size: 1.2rem;
		color: #b2b2c3;
		font-weight: bold;
		border: none;
		padding: 0 1.5rem;
		text-decoration: none;
		&:hover {
			color: #b2b2c3;
			background-color: #fff;
		}
		&-active {
			font-size: 1.8rem;
			color: #4d4d57;
			text-decoration: none;
			&:hover {
				font-size: 1.8rem;
				color: #4d4d57;
				background-color: #fff;
			}
		}
		&-left-circle {
			border-radius: 50%;
			height: 40px;
			width: 40px;
			border: 1.5px solid #dbdbe3;
			border-top-left-radius: 50% !important;
			border-bottom-left-radius: 50% !important;
			margin: 0;
			position: relative;
			padding: 0;
			transition: all 0.3s ease;
			&:after {
				content: '';
				position: absolute;
				height: 9px;
				width: 2px;
				background-color: #b2b2c3;
				top: 17px;
				left: 16px;
				transform: rotate(-45deg);
				transition: all 0.3s ease;
			}
			&:before {
				content: '';
				position: absolute;
				height: 9px;
				width: 2px;
				background-color: #b2b2c3;
				top: 11px;
				left: 16px;
				transform: rotate(45deg);
				transition: all 0.3s ease;
			}
			&:hover {
				border: 1.5px solid #83848e;
				&:after, &:before {
					background-color: #83848e;
				}
			}
		}
		&-right-circle {
			border-radius: 50%;
			height: 40px;
			width: 40px;
			border: 1.5px solid #dbdbe3;
			border-top-right-radius: 50% !important;
			border-bottom-right-radius: 50% !important;
			margin: 0;
			position: relative;
			padding: 0;
			transition: all 0.3s ease;
			&:after {
				content: '';
				position: absolute;
				height: 9px;
				width: 2px;
				background-color: #b2b2c3;
				top: 17px;
				left: 18px;
				transform: rotate(45deg);
				transition: all 0.3s ease;
			}
			&:before {
				content: '';
				position: absolute;
				height: 9px;
				width: 2px;
				background-color: #b2b2c3;
				top: 11px;
				left: 18px;
				transform: rotate(-45deg);
				transition: all 0.3s ease;
			}
			&:hover {
				border: 1.5px solid #83848e;
				&:after, &:before {
					background-color: #83848e;
				}
			}
		}
	}
}
