.s-title-box {
    &__wrapper {
		width: 100%;
		max-width: 640px;
        margin: 0 auto 7.5rem auto;
        padding: 4rem 0 2rem 0;
        @media (max-width: $sw-md-max) {
            padding: 8.5rem 0 2rem 0;
        }
        @media (max-width: $sw-xs-max) {
            padding-right: 2rem;
            padding-left: 2rem;
        }
        &.box__wrapper_lg {
            max-width: 960px;
            @media (max-width: $sw-md-max) {
                padding: 10rem 2rem 2rem 2rem;
                margin-bottom: 0;
            }
        }
        &.box__bordered {
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 0);
                width: 100%;
                max-width: 319px;
                height: 1px;
                background-color: $stormGray;
                opacity: 0.36;
                @media (max-width: $sw-xs-max) {
                    max-width: 260px;
                }
                @media (max-width: $sw-xx-max) {
                    max-width: 180px;
                }
            }
        }
	}
	&__main-text {
		text-align: center;
	}
    &__title {
		h1, h2, h3, h4, h5, h6 {
			color: #3e3e48;
			font-weight: 900;
            font-size: 1.4rem;
            @media (max-width: $sw-xs-max) {
                font-size: 1.6rem;
                line-height: 2.1rem;
            }
		}
    }
    &__subtitle {
        text-align: justify;
        padding: 1.1rem 0;
		@media (max-width: $sw-md-max) {
            padding: 0.9rem 0;
        }
        @media (max-width: $sw-xs-max) {
            font-size: 1.6rem;
            line-height: 2.1rem;
        }
        &.subtitle_large {
            padding: 1.1rem 0.7rem;
        }
		p {
            font-size: 1.4rem;
			@media (max-width: $sw-sm-max) {
				line-height: 2.6rem;
			}
		}
        ul {
          font-size: 1.5rem;
          font-weight: normal;
          text-transform: none;
          li {
            margin-bottom: 10px;
          }
        }
	}
}
