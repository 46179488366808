.seo-description__wrapper{
	padding-bottom: 5rem;
	width: 100%;
	max-width: 50%;
	margin-left: auto;
	margin-right: auto;
	text-align: justify;
	@media (max-width: $sw-md-max) {
		max-width: 100%;
		padding-left: 12rem;
		padding-right: 12rem;
	}
	@media (max-width: $sw-sm-max) {
		padding-left: 14rem;
		padding-right: 14rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	@media (max-width: $sw-xx-max) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}
}
