// .select-box{
// 	.collapse-box{
// 		padding-top: 2.5rem;
// 		position: relative;
// 		.collapse-box__button{
// 			display: block;
// 			height: 2.2rem;
// 			border-bottom: 1px solid #b1b1c1;
// 			&:after{
// 				content: '';
// 				right: 12px;
// 				bottom: 9px;
// 				width: 0.6rem;
// 				height: 0.6rem;
// 				display: block;
// 				position: absolute;
// 				margin-bottom: .6rem;
// 				transform: rotateZ(45deg);
// 				border-top: 2px solid $cornflowerBlue;
// 				border-left: 2px solid $cornflowerBlue;
// 			}
// 			&:before{
// 				content: '';
// 				right: 12px;
// 				bottom: 9px;
// 				width: 0.6rem;
// 				height: 0.6rem;
// 				display: block;
// 				position: absolute;
// 				transform: rotateZ(135deg);
// 				border-top: 2px solid $cornflowerBlue;
// 				border-right: 2px solid $cornflowerBlue;
// 			}
// 			span{
// 				display: none;
// 				font-size: 1.2rem;
// 				line-height: 2.2rem;
// 				letter-spacing: .1rem;
// 				text-transform: uppercase;
// 			}
// 			i{
// 				left: 0;
// 				right: 0;
// 				top: 2.5rem;
// 				bottom: 1px;
// 				color: #b2b2c3;
// 				font-weight: 300;
// 				font-size: 1.2rem;
// 				position: absolute;
// 				font-style: normal;
// 				line-height: 2.2rem;
// 				pointer-events: none;
// 				letter-spacing: .1rem;
// 				transition: all 0.3s ease;
// 				text-transform: uppercase;
// 			}
// 		}
// 		.collapse-box__content{
// 			left: 0;
// 			right: 0;
// 			top: 100%;
// 			opacity: 0;
// 			z-index: 9;
// 			margin-top: 1rem;
// 			max-height: 29rem;
// 			position: absolute;
// 			visibility: hidden;
// 		}
// 		input:checked + .collapse-box__button + .collapse-box__content{
// 			opacity: 1;
// 			visibility: visible;
// 		}
// 		&.selected{
// 			.collapse-box__button{
// 				span{
// 					display: block;
// 				}
// 				i{
// 					top: 0;
// 					left: 0;
// 					bottom: auto;
// 					font-size: 1rem;
// 					letter-spacing: 0.2rem;
// 				}
// 			}
// 		}
// 	}
// }

.select-box{
	position: relative;
}

.select-box__placeholder{
	color: #b2b2c3;
	font-size: 1rem;
	font-weight: 300;
	line-height: 1.4rem;
	pointer-events: none;
	margin-bottom: 1.1rem;
	letter-spacing: 0.2rem;
	text-transform: uppercase;
	@media (max-width: $sw-xs-max) {
		font-size: 1.2rem;
	}
}

.select-box__options{
	position: relative;
	&:after{
		z-index: 1;
		content: '';
		right: 12px;
		bottom: 9px;
		width: 0.6rem;
		height: 0.6rem;
		display: block;
		position: absolute;
		pointer-events: none;
		margin-bottom: .6rem;
		transform: rotateZ(45deg);
		border-top: 2px solid $cornflowerBlue;
		border-left: 2px solid $cornflowerBlue;
	}
	&:before{
		z-index: 1;
		content: '';
		right: 12px;
		bottom: 9px;
		width: 0.6rem;
		height: 0.6rem;
		display: block;
		position: absolute;
		pointer-events: none;
		transform: rotateZ(135deg);
		border-top: 2px solid $cornflowerBlue;
		border-right: 2px solid $cornflowerBlue;
	}
}


select{
	width: 100%;
	border: none;
	height: 2.2rem;
	font-size: 1.2rem;
	position: relative;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none !important;
	border-bottom: 1px solid #b1b1c1;
}

// .select-box__touch{
// 	display: none;
// 	@media (max-width: $sw-sm-max) {
// 		display: block;
// 	}
// }

// .select-box__desctop{
// 	display: block;
// 	@media (max-width: $sw-sm-max) {
// 		display: none;
// 	}
// }

// .select-box-button{

// }

// .select-box-list{
// 	padding-top: 1.6rem;
// 	padding-bottom: 1.6rem;
// 	background-color: #fff;
// 	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
// 	ul{
// 		overflow: hidden;
// 		overflow-y: auto;
// 		max-height: 25.8rem;
// 		li{

// 		}
// 	}
// }

// .select-box-check{
// 	input{
// 		display: none;
// 		&:checked + span{
// 			background: $frenchGray;
// 		}
// 	}
// 	label{
// 		margin: 0;
// 		padding: 0;
// 		display: block;
// 		cursor: pointer;
// 		&:hover{
// 			background-color: $athensGray;
// 		}
// 	}
// 	span{
// 		height: 3.2rem;
// 		display: block;
// 		overflow: hidden;
// 		white-space: nowrap;
// 		line-height: 3.2rem;
// 		padding-left: 2.4rem;
// 		padding-right: 2.4rem;
// 		letter-spacing: 1.3px;
// 		text-overflow: ellipsis;
// 		text-transform: uppercase;
// 	}
// }