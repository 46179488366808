.section-product{
	padding-top: 8.4rem;
	padding-bottom: 8.4rem;
	@media (max-width: $sw-md-max) {
		padding-top: 4.4rem;
	}
	@media (max-width: $sw-md-max) {
		padding-top: 5.6rem;
		padding-bottom: 5.6rem;
	}
	background-color: $athensGray;
}

.product__wrapper{
	display: flex;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	padding-left: 5rem;
	padding-right: 5rem;
	max-width: 153.8rem;
	@media (max-width: $sw-xs-max) {
		padding-left: 1.6rem;
		padding-right: 1.6rem;
		flex-direction: column;
	}
}

.product__view{
	width: 63.7rem;
	flex-shrink: 0;
	margin-right: 16.4rem;
	@media (max-width: 1568px) {
		width: 46rem;
		margin-right: 3rem;
	}
	@media (max-width: $sw-sm-max) {
		width: 39.5%;
	}
	@media (max-width: $sw-xs-max) {
		left: 0;
		right: 0;
		width: 100%;
		top: 10.9rem;
		position: absolute;
	}
}

.product__carousel{
	.lSSlideOuter{
		.lSPager{
			margin-top: 2.4rem !important;
			@media (max-width: $sw-xs-max) {
				margin-top: 3.2rem !important;
			}
		}
	}
}

.product__carousel.float {
	position: fixed;
}

.product__carousel-items{

}

.product__carousel-item{
	width: 63.7rem;
	height: 72.1rem;
	@media (max-width: 1568px) {
		width: 46rem;
	}
	@media (max-width: $sw-md-max) {
		height: 64rem;
	}
	@media (max-width: $sw-sm-max) {
		width: 100%;
		height: 54rem;
	}
	@media (max-width: $sw-xs-max) {
		height: 27rem;
	}
	picture{
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		img{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 100%;
			position: absolute;
		}
	}
}

.product__carousel-control{
	margin-top: 2.4rem;
	@media (max-width: $sw-xs-max) {
		margin-top: 3.2rem;
	}
	.carousel-dots{
		justify-content: center;
	}
}

.product__details{
	width: 100%;
	padding-top: 5.1rem;
	@media (max-width: $sw-md-max) {
		padding-top: 0;
	}
	.breadcrumb{
		margin-bottom: 5.7rem;
		@media (max-width: $sw-xs-max) {
			margin-bottom: 0;
			padding-bottom: 44.2rem;
		}
		&__items{
			justify-content: flex-start;
			@media (max-width: $sw-xs-max) {
				justify-content: center;
			}
		}
	}
}

.product__name{
	h1{
		font-weight: 700;
	}
	// font-size: 3.7rem;
	// letter-spacing: .3rem;
	// color: $cornflowerBlue;
	// text-transform: uppercase;
	h1{
		@media (max-width: $sw-xs-max) {
			font-size: 4.6rem;
			text-align: center;
		}
	}
}

.product__caption {
	font-weight: 400;
	margin-top: 2.2rem;
	line-height: 2.2rem;
	color: $cornflowerBlue;
	letter-spacing: .07rem;
	@media (max-width: $sw-xs-max) {
		font-size: 1.8rem;
		text-align: center;
		margin-top: 0.9rem;
		line-height: 2.8rem;
	}
}

.product__price{
	font-weight: 700;
	font-size: 2.8rem;
	margin-top: 1.5rem;
	color: $cornflowerBlue;
	text-transform: uppercase;
	@media (max-width: $sw-xs-max) {
		font-size: 5rem;
		text-align: center;
		margin-top: 2.4rem;
	}
}

.product__priceBaseEd{
	font-weight: 400;
	font-size: 1.1rem;
	margin-top: 0;
	color: $cornflowerBlue;
	text-transform: lowercase;
	@media (max-width: $sw-md-max) {
		font-size: 1.2rem;
	}
	@media (max-width: $sw-xs-max) {
		font-size: 1.8rem;
		text-align: center;
		margin-top: 0.5rem;
	}
}

.product__buy{
	margin-top: 3.4rem;
	@media (max-width: $sw-md-max) {
		margin-top: 2.4rem;
	}
	@media (max-width: $sw-xs-max) {
		text-align: center;
		margin-top: 2.5rem;
	}
	.title {
		margin-bottom: 1rem;
		font-weight: bold;
		color: #3e3e48;
	}
	a {
		width: 280px;
		display: block;
		font-size: 1.2rem;
		margin-bottom: 1rem;
		@media (max-width: $sw-xs-max) {
			margin: 2rem auto;
			height: 7.2rem;
			font-size: 1.5rem;
			min-width: 34rem;
			line-height: 7.2rem;
		}
	}
}

.product__description-items{
	margin-top: 4.9rem;
	@media (max-width: $sw-xs-max) {
		margin-top: 7.1rem;
	}
}

.product__description-item{
	border-top: 1px solid #dfdfe8;
	border-bottom: 1px solid #dfdfe8;
	&:not(:last-child){
		margin-bottom: -1px;
	}
	&.collapse-box{
		input:checked + .collapse-box__button{
			&:before{
				transform: rotateZ(-90deg);
			}
		}
		input:checked + .collapse-box__button + .collapse-box__content{
			max-height: 1500rem;
			padding-top: 1.6rem;
			padding-bottom: 2.4rem;
		}
	}
}

.product__description-item-title{
	width: 100%;
	display: block;
	text-align: left;
	color: $stormGray;
	font-size: 1.2rem;
	position: relative;
	padding-top: 1.6rem;
	padding-right: 3.6rem;
	padding-bottom: 1.7rem;
	letter-spacing: 0.25rem;
	text-transform: uppercase;
	transition: all 0.3s ease;
	@media (min-width: $sw-md-min) {
		&:hover{
			padding-left: 1rem;
		}
	}
	@media (max-width: $sw-xs-max) {
		font-size: 2.1rem;
		padding-top: 3.4rem;
		padding-right: 4.8rem;
		padding-bottom: 3.6rem;
	}
	@media (max-width: $sw-xs-max) {
		font-size: 1.8rem;
		padding-top: 2.8rem;
		padding-bottom: 3rem;
	}
	&:after{
		top: 50%;
		height: 1px;
		content: '';
		right: 1.2rem;
		width: 1.1rem;
		display: block;
		position: absolute;
		background-color: $stormGray;
		@media (max-width: $sw-xs-max) {
			height: 2px;
			width: 2.5rem;
			margin-top: -1px;
		}
	}
	&:before{
		top: 50%;
		width: 1px;
		content: '';
		right: 1.7rem;
		display: block;
		height: 1.1rem;
		position: absolute;
		margin-top: -.5rem;
		transition: all 0.3s ease;
		background-color: $stormGray;
		@media (max-width: $sw-xs-max) {
			width: 2px;
			right: 2.3rem;
			height: 2.5rem;
			margin-top: -1.2rem;
		}
	}
}

.product__description-item-content{
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	@media (max-width: $sw-md-max) {
		padding-left: 0;
		padding-right: 0;
	}
	p{
		@media (min-width: $sw-md-min) {
				font-size: 1.3rem;
				letter-spacing: 0;
				line-height: 1.8rem;
				margin-bottom: 1.8rem;
		}
		@media (max-width: $sw-xs-max) {
			font-size: 1.8rem;
			line-height: 2.2rem;
		}
		@media (max-width: $sw-xx-max) {
			font-size: 1.6rem;
			line-height: 2rem;
		}
	}
	//a:hover {
	//	color: $black;
	//}
	ol, ul:not(.h-reset-list):not(.lSPager) {
		> li {
			color: $cornflowerBlue;
			font-size: 1.3rem;
			margin-bottom: 0;
		}
	}
	picture{
		display: block;
		margin-bottom: 3.2rem;
	}
	.double-col-box{
		padding-right: 8.4rem;
		@media (max-width: $sw-sm-max) {
			padding-right: 3.2rem;
		}
		@media (max-width: $sw-xs-max) {
			padding-right: 0;
		}
	}
}

