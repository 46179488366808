// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Custom Color system
// $scorpion: #585858;
// Color system
// Options
// Spacing
// Body
	//
	// Settings for the `<body>` element.

$gothamPro: 'GothamPro', sans-serif;
$gothamProNarrow: 'GothamProNarrow', sans-serif;

//colors variables

$white: #ffffff;
$black: #000000;
$cornflowerBlue: #3e3e48;
$athensGray: #f2f2f7;
$frenchGray: #b7b7bf;
$charade: #2b2b38;
$stormGray: #4d4d57;
$llGray: #e9e9e9;



//old
$glacier: #87b4cb;
$linkWater: #cee6f3;
$oxfordBlue: #344852;
$tePapaGreen: #1d323e;


	$body-bg:       $white;
	$body-color:    $stormGray;

	//grey
	$hue-grey: #567;
	$lite-grey: #9ab;

	//blue
	$hue-blue: #345;
	//$lite-blue: #445ae3; 

	//violet
	$lite-violet: #445ae3;


	$general-hue-lite: $hue-grey;//grey
	$general-lite: $lite-grey;//grey

	$general-hue-dark: $hue-blue;//blue
	//$general-lightness: $lite-blue;//blue

	$general-bright: $lite-violet;//violet
	

$transition_duration: 0.3s;

// Links
	//
	// Style anchor elements.

	$link-decoration:       underline;
	$link-hover-decoration: none;

// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$headings-font-family: $gothamPro;

$font-size-base: 1.4rem; // Assumes the browser default, typically `14px`

$line-height-base: 1.4;

// $h1-font-size: 2.4rem;
// $h2-font-size: 2rem;
// $h3-font-size: 1.75rem;
// $h4-font-size: 1.5rem;
// $h5-font-size: 1.25rem;
// $h6-font-size: 1rem;


$h1-font-size: 6rem;
$h2-font-size: 2.4rem;
$h3-font-size: 2.2rem;
$h4-font-size: 2.0rem;
$h5-font-size: 1.8rem;
$h6-font-size: 1.6rem;

// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts 
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
	25: 25%,
	50: 50%,
	75: 75%,
	100: 100%
) !default;

// Body
//
// Settings for the `<body>` element.


// Links
//
// Style anchor elements.

// $link-color:            $brand-primary !default;
$link-color:            #3E6CE3;
$link-decoration:       underline;
// $link-hover-color:      darken($link-color, 15%) !default;
/*$link-hover-color:      darken($link-color, 15%);*/
$link-hover-color:      $black;
$link-hover-decoration: none ;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$sw-xx-min: 0;//0
$sw-xs-min: 480px;//0//576
$sw-sm-tin: 576px;//576//768
$sw-sm-min: 768px;
$sw-md-min: 992px;
$sw-lg-min: 1280px;
$sw-xl-min: 1920px;

$sw-xx-max:$sw-xs-min - 1px;//479px
$sw-xs-max:$sw-sm-min - 1px;
$sw-sm-max:$sw-md-min - 1px;
$sw-md-max:$sw-lg-min - 1px;
$sw-lg-max:$sw-xl-min - 1px;

$grid-breakpoints: (
	xs: $sw-xs-min,
	sm: $sw-sm-min,
	md: $sw-md-min,
	lg: $sw-lg-min,
	xl: $sw-xl-min,
);
// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 24;
$grid-gutter-width: 3rem;
$grid-gutter-width-base: $grid-gutter-width;
$grid-gutter-widths: (
	xs: $grid-gutter-width-base,
	sm: $grid-gutter-width-base,
	md: $grid-gutter-width-base,
	lg: $grid-gutter-width-base,
	xl: $grid-gutter-width-base
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	xs: 100%,
	sm: 100%,
	md: 100%,
	lg: 100%,
	xl: 100%
);
// $container-max-widths: (
// 	xs: 100%,
// 	sm: 76.8rem,
// 	md: 96.2rem,
// 	lg: 126rem,
// 	xl: 126rem
// );
/*@include _assert-ascending($container-max-widths, "$container-max-widths");*/

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: $gothamPro;
$font-family-serif:      $gothamPro;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

// -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
// font-family: 'Roboto', sans-serif;
// font-family: 'Lato', sans-serif;
// font-family: 'Fira Sans', sans-serif;


// $font-size-base: 1.6rem; // Assumes the browser default, typically `16px`
// $font-size-lg:   1.25rem !default;
// $font-size-sm:   .875rem !default;
// $font-size-xs:   .75rem !default;

// $font-weight-normal: normal !default;
// $font-weight-bold: bold !default;

// $font-weight-base: $font-weight-normal !default;
// $line-height-base: 1.5 !default;