.modal {
    padding-left: 0 !important;
    &-backdrop {
        background: rgba(0,0,0,.58);
    }
    &-dialog {
        max-width: 100%;
        width: 100%;
        margin: 0;
        pointer-events: auto;
    }
    &__wrapper {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $sw-xx-max) {
            align-items: flex-start;
        }
    }
    &__block {
        width: 526px;
        height: auto;
        border-radius: 3px;
        @media (max-width: $sw-xs-max) {
            width: 100%;
            max-width: 526px;
        }
        @media (max-width: $sw-xs-max) {
            height: 100%;
        }
    }
    &__header {
        min-height: 67px;
        border-radius: 3px 3px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fbfbfa;
        border-bottom: 2px solid #e3e4e1;
        position: relative;
        @media (max-width: $sw-xs-max) {
            height: 9.8rem;
        }
        &-title {
            color: $charade;
            font-size: 1.4rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            @media (max-width: $sw-xs-max) {
                font-size: 2.4rem;
                letter-spacing: 2.4px;
            }
        }
        .modal-close {
            width: 39px;
            height: 39px;
            display: none;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translate(0,-50%);
            cursor: pointer;
            @media (max-width: $sw-xx-max) {
                display: block;
            }
            &:before, &:after {
                content: '';
                display: block;
                width: 2px;
                height: 50px;
                background-color: #7d7d7d;
                position: absolute;
                left: 19px;
                top: -5px;
            }
            &:before {
                transform: rotate(-45deg);
            }
            &:after {
                transform: rotate(45deg);
            }
        }
    }
    &__body {
        background-color: #fff;
        border-radius: 0 0 3px 3px;
        padding: 5.1rem 5.9rem 3.5rem 5.9rem;
        @media (max-width: $sw-xs-max) {
            padding: 7.8rem 3.2rem 1.8rem 3.2rem;
        }
        @media (max-width: $sw-xs-max) {
            height: calc(100% - 9.8rem);
        }
        &-info {
            color: $stormGray;
            font-size: 1.6rem;
            font-weight: 300;
            line-height: 1.8rem;
            letter-spacing: 0.32px;
            display: inline-block;
            margin-bottom: 7.4rem;
            @media (max-width: $sw-xs-max) {
                font-size: 1.6rem;
                line-height: 1.8rem;
            }
        }
        select.form-control{
            padding-left: 0 !important;
            font-weight: 300 !important;
            @media (max-width: $sw-xs-max) {
                font-size: 1.8rem !important;
                letter-spacing: 0.5px;
            }
        }
        .s-profile-inner__submit {
            justify-content: flex-end;
            margin-top: 4.5rem;
            @media (max-width: $sw-xx-max) {
                flex-wrap: wrap;
            }
            .btn {
                & + .btn {
                    margin-left: 12px;
                    @media (max-width: $sw-xx-max) {
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }
                @media (max-width: $sw-sm-max) {
                    padding: 0 1.6rem;
                    height: 4rem;
                    font-size: 1rem;
                }
                @media (max-width: $sw-xs-max) {
                    padding: 0;
                    width: 50%;
                    font-size: 1.6rem;
                    height: 6.5rem;
                }
                @media (max-width: $sw-xx-max) {
                    width: 100%;
                }
            }
        }
    }
    &__submit {
        margin-top: 10rem;
        display: flex;
        justify-content: flex-end;
        @media (max-width: $sw-xs-max) {
            margin-top: 9rem;
        }
        @media (max-width: $sw-xx-max) {
            flex-wrap: wrap;
        }
        .btn {
            min-width: 120px;
            @media (max-width: $sw-xs-max) {
                min-width: auto;
                width: 50%;
                height: 65px;
                font-size: 1.6rem;
            }
            @media (max-width: $sw-xx-max) {
                width: 100%;
            }
            & + .btn {
                margin-left: 12px;
                @media (max-width: $sw-xx-max) {
                    margin-left: 0;
                    margin-top: 2rem;
                }
            }
        }
    }
}