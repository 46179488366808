.section-carousel{
	@media (max-width: $sw-xs-max) {
		display: none;
	}
}

.carousel__wrapper{
	position: relative;
	.lSPager{
		left: 72.7%;
		z-index: 11;
		bottom: 13rem;
		position: absolute;
		@media (max-width: $sw-md-max) {
			left: 72%;
			bottom: 8.5rem;
		}
		@media (max-width: $sw-sm-max) {
			left: 68%;
		}
		li{
			a{
				background-color: transparent;
				border: .2rem solid $stormGray;
			}
			&:not(.active){
				a{
					@media (min-width: $sw-md-min) {
						&:hover{
							border: .2rem solid $white;
							background-color: transparent;
						}
					}
				}
			}
			&.active{
				a{
					background-color: $stormGray;
				}
			}
		}
	}
}

.carousel__item{
	height: 72rem;
	background-color: #000000;
	@media (max-width: $sw-md-max) {
		height: 58rem;
	}
	.container{
		height: 100%;
	}
}

.carousel__item-wrapper{
	height: 100%;
	position: relative;
}

.carousel__item-view{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	position: absolute;
	picture{
		width: 100%;
		height: 100%;
		&.h-object-fit{
			img{
				object-fit: contain;
				object-position: 40% 50%;
				@media (max-width: $sw-md-max) {
					object-position: 0% 50%;
				}
				@media (max-width: $sw-sm-max) {
					object-position: -100% 50%;
				}
			}
		}
	}
}

.carousel__item-detail{
	z-index: 1;
	position: relative;
    padding-left: 72.7%;
    padding-top: 30.1rem;
    padding-right: 4.2rem;
	@media (max-width: $sw-md-max) {
		padding-top: 26rem;
		padding-left: 72%;
	}
	@media (max-width: $sw-sm-max) {
		padding-left: 68%;
	}
}

.carousel__item-logo{
	margin-left: -.5rem;
	a{
		svg{
			fill: $white;
			font-size: 13rem;
			transition: fill .3s ease;
		}
		@media (min-width: $sw-md-min) {
			&:hover{
				svg{
					fill: $stormGray;
				}
			}
		}
	}
}

.carousel__item-title{
	color: $white;
	overflow: hidden;
	font-weight: 900;
	font-size: 1.8rem;
	margin-top: 6.2rem;
	white-space: nowrap;
	letter-spacing: .15rem;
	text-overflow: ellipsis;
	text-transform: uppercase;
	@media (max-width: $sw-md-max) {
		margin-top: 4.2rem;
	}
}

.carousel__item-caption{
	overflow: hidden;
	max-height: 5.4rem;
	margin-top: 1.6rem;
	line-height: 1.8rem;
}

.carousel__item-action{
	margin-top: 2.2rem;
	a, button{
		min-width: 14rem;
		@media (max-width: $sw-md-max) {
			min-width: 15.5rem;
		}
	}
}

.carousel__controls{
	left: 72.7%;
	bottom: 13rem;
	position: absolute;
	@media (max-width: $sw-md-max) {
		left: 59.7%;
		bottom: 8.5rem;
	}
	@media (max-width: $sw-sm-max) {
		left: 50%;
	}
}

.lSPager{
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	li{
		&:not(:first-child){
			margin-left: 1.3rem;
		}
		a{
			width: 1rem;
			height: 1rem;
			display: block;
			overflow: hidden;
			border-radius: 50%;
			text-indent: -999em;
			transition: all 0.3s ease;
			background-color: $stormGray;
			border: .2rem solid $stormGray;
		}
		&:not(.active){
			a{
				@media (min-width: $sw-md-min) {
					&:hover{
						background-color: $black;
						border: .2rem solid $black;
					}
				}
			}
		}
		&.active{
			a{
				background-color: transparent;
			}
		}
	}
}
