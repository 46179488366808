.s-cta {
    width: 100%;
    &__wrapper {
        width: 100%;
        height: 480px;
        max-width: 1425px;  
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        padding: 32px 0 60px 0;
        display: flex;
        align-items: flex-end;
        @media (max-width: $sw-md-max) {
            padding: 76px 0 73px 0;
        }
        @media (max-width: $sw-xs-max) {
            height: 380px;
        }
        .btn {
            font-size: 1.2rem;
            padding: 0 2.1rem;
        }
    }
    &__top {
        width: 100%;
    }
    &__bottom {
        width: 100%;
    }
    &__logo {
        width: 100%;
        text-align: center;
        .icon-logo {
            font-size: 8rem;
        }
    }
}

.cta-hero {
    background-image: url('../img/about/about1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .s-cta__wrapper {
        height: 640px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: $sw-md-max) {
            height: 580px;
        }
        @media (max-width: $sw-xs-max) {
            height: 380px;
        }
    }
    .box-title {
        margin-top: 1.5rem;
    }
    .box-title__name {
        color: #dfdfe9;
    }
    .breadcrumb__item .link:hover {
        color: $frenchGray;
    }
}
.cta-recognizability {
    background-image: url('../img/about/about2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.cta-principles {
    background-image: url('../img/about/about3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: $sw-md-max) {
        background-image: url('../img/about/about3-1280.jpg');
    }
    @media (max-width: $sw-xx-max) {
        background-image: url('../img/about/about3-480.jpg');
    }
    .s-cta__wrapper {
        flex-wrap: wrap;
    }
}
.cta-cosmetics {
    background-image: url('../img/about/about4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}