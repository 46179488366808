.modal-backdrop{
	background: rgba(216, 216, 216, 0.74);
	&.show{
		opacity: 1;
	}
}

.modal-dialog{
	width: 100%;
	margin: 4rem auto;
	max-width: 34.4rem;
}

.modal-content{
	border: none;
	border-radius: 0;
	padding-bottom: 3.4rem;
	box-shadow: 0 0 .8rem rgba(112, 112, 112, 0.5);
	@media (max-width: $sw-xs-max) {
		padding-bottom: 3.2rem;
	}
}