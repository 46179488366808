.s-ask-question {
	background-color: #f2f2f7;
	padding: 3.3rem 0 16rem 0;
	// margin-bottom: -5.5rem;
	@media (max-width: $sw-md-max) {
		padding-bottom: 12.6rem;
		//margin-bottom: -10.5rem;
	}
	&__wrapper {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		@media (max-width: $sw-md-max) {
			max-width: 495px;
		}
		.s-register-inner__wrapper {
			@media (max-width: $sw-md-max) {
				display: flex;
			}
		}
		.s-profile-inner__input.input-checkbox {
			@media (max-width: $sw-md-max) {
				order: 2;
			}
		}
		.submit-box {
			@media (max-width: $sw-md-max) {
				order: 3;
			}
		}
	}
	&__main-text {
		text-align: center;
		@media (max-width: $sw-xs-max) {
			padding: 0 1.5rem;
		}
	}
	&__title {
		h3 {
			font-size: 1.6rem;
		}
	}
	&__subtitle {
		padding: 0.6rem 12.7rem;
		@media (max-width: $sw-md-max) {
			padding: 0.9rem 0;
		}
		p {
			@media (max-width: $sw-sm-max) {
				line-height: 2.6rem;
			}
			@media (max-width: $sw-xs-max) {
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}
	}
	&__form-wrapper {
		.s-profile-inner__side.full-side {
			margin-top: 0;
			.s-profile-inner__input {
				max-width: 100%;
			}
		}
	}
	&__form-hero {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-top: 2rem;
		position: relative;
		@media (max-width: $sw-md-max) {
			padding-left: 8rem;
		}
		@media (max-width: $sw-sm-max) {
			padding-right: 8rem;
		}
		@media (max-width: $sw-xs-max) {
			padding-left: 0;
			padding-right: 0;
			padding-top: 3rem;
		}
		.s-profile-inner__input {
			width: 100%;
			max-width: 440px;
		}
	}
	&__form-email {
		width: 440px;
		position: relative;
		@media (max-width: $sw-md-max) {
			width: 100%;
		}
		.form-control {
		background-color: transparent;
		border: none;
		box-shadow: none !important;
		border-bottom: 1px solid #b2b2c3;
		border-radius: 0;
		padding: 0;
		margin: 0;
		font-size: 12px;
		font-weight: 300;
		line-height: 25px;
		text-transform: uppercase;
		color: #3e3e48;
		text-align: left;
		height: 32px;
		&::-webkit-input-placeholder { 
			color: #b2b2c3;
			font-size: 10px;
			font-weight: 300;
			line-height: 25px;
			text-transform: uppercase;
			}
		}	
	}
	&__form-question {
		width: 100%;
		position: relative;
		margin-top: 17px;
		@media (max-width: $sw-md-max) {
		margin-top: 20px;
		}
		@media (max-width: $sw-xs-max) {
			margin-top: 31px;
	}
		.form-control {
			background-color: transparent;
			border: none;
			box-shadow: none !important;
			border-bottom: 1px solid #b2b2c3;
			border-radius: 0;
			padding: 0;
			margin: 0;
			font-size: 12px;
			font-weight: 300;
			line-height: 25px;
			text-transform: uppercase;
			color: #3e3e48;
			text-align: left;
			height: 32px;
			max-height: 300px;
			min-height: 32px;
			}	
			.form-input:focus~.form-label,
			.form-input:not(:focus):valid~.form-label {
				top: -10px;
				font-size: 10px;
		}
	}
	&__form-checkbox {
		display: flex;
		align-items: center;
		margin-top: 2px;
		.check-icon {
			top: 4px;
		}
		// &-text {
		// 	span, a {
		// 		text-transform: uppercase;
		// 		color: #3e3e48;
		// 		font-size: 10px;
		// 		font-weight: 300;
		// 		line-height: 10px;
		// 		letter-spacing: 2px;
		// 		@media (max-width: $sw-md-max) {
		// 			letter-spacing: 1px;
		// 			white-space: nowrap;
		// 		}
		// 		@media (max-width: $sw-xs-max) {
		// 			font-size: 1.4rem;
		// 			letter-spacing: normal;
		// 		}
		// 	}
		// 	a {
		// 		text-decoration: underline;
		// 	}
		// }
	}
	&__form-name {
		display: none;
		width: 100%;
		.form-control {
			background-color: transparent;
			border: none;
			box-shadow: none !important;
			border-bottom: 1px solid #b2b2c3;
			border-radius: 0;
			padding: 0;
			margin: 0;
			font-size: 12px;
			font-weight: 300;
			line-height: 25px;
			text-transform: uppercase;
			color: #3e3e48;
			text-align: left;
			height: 32px;
			max-height: 300px;
			min-height: 32px;
			}	
		@media (max-width: $sw-md-max) {
			display: block;
			margin-bottom: 15px;
	}
		@media (max-width: $sw-xs-max) {
			margin-bottom: 33px;
	}
}
	&__form-label {
		color: #b2b2c3;
		font-size: 10px;
		font-weight: 300;
		line-height: 18px;
		text-transform: uppercase;
		@media (max-width: $sw-xs-max) {
			font-size: 1.4rem;
		}
	}
	&__form-submit {
		margin: 0 auto;
		margin-top: 70px;
		@media (max-width: $sw-md-max) {
			margin-top: 38px;
	}
	@media (max-width: $sw-xs-max) {
		margin-top: 63px;
}
		button {
			background-color: #2b2b38;
			color: #ffffff;
			font-size: 10px;
			font-weight: 400;
			line-height: 24px;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			padding: 8px 31px;
			text-align: center;
			position: relative;
			&::after {
				opacity: 0;
				top: -.1rem;
				content: '';
				left: -.1rem;
				right: -.1rem;
				bottom: -.1rem;
				display: block;
				visibility: hidden;
				position: absolute;
				-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
				border: 0.1rem solid #ffffff;
			}
			&:hover::after{
				top: .3rem;
				opacity: 1;
				left: .3rem;
				content: '';
				right: .3rem;
				bottom: .3rem;
				visibility: visible;
			}
			@media (max-width: $sw-md-max) {
				padding: 8px 40px;
				margin-left: -83px;
			}
			@media (max-width: $sw-sm-max) {
				margin-left: 0;
			}
			@media (max-width: $sw-xs-max) {
				padding: 24px 56px;
				font-size: 25px;
			}
		}
	}
}
