.section-main {
  width: 100%;
  height: auto;
}

.section-main .main-block-1 {
   background: #000000 url(/s/img/main/main-bg-1-2.jpg) no-repeat center center;
   background-size: cover;
   width: 100%;
   padding: 24% 0 25% 0;
   box-sizing: border-box;
   position: relative;
   min-height: 300px;
}
.section-main .main-block-1 .text {
  line-height: 2.9vw;
  position: absolute;
  left: 15%;
  top: 23%;
  font-size: 2.54vw;
  color: #ffffff;
}
.section-main .main-block-1 .text #prodCount {
  margin-top: 3vw;
  font-weight: 700;
  font-size: 10.74vw;
  line-height: 10.5vw;
  font-family: Monaco, monospace;
}
.section-main .main-block-1 .text #prodText {
  margin-top: 2vw;
  font-weight: 700;
  font-size: 4.74vw;
  line-height: 4.9vw;
}

.section-main .main-block-2 {
  background: #e5e5e5 url(/s/img/main/main-bg-2-1.png) no-repeat bottom right;
  background-size: auto 88%;
  background-position-x: 94%;
  width: 100%;
  padding: 24% 0 25% 0;
  box-sizing: border-box;
  position: relative;
}

.section-main .main-block-2 .name {
  color: #242424;
  width: 50px;
  text-transform: uppercase;
  position: absolute;
  left: 20%;
  bottom: 23%;
  font-size: 6vw;
  font-weight: 900;
  line-height: 6vw;
}

.section-main .main-block-2 .quote {
  background-color: #a5a5a5;
  width: 45vw;
  padding: 22px 30px;
  color: #ffffff;
  font-style: italic;
  position: absolute;
  font-size: 2vw;
  left: 11%;
  bottom: 59%;
  font-weight: 300;
  line-height: 2.7vw;
}

.section-main .main-block-2 .quote:after {
  -moz-transform: skewY(10deg);
  -ms-transform: skewY(10deg);
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);
  content: "";
  position: absolute;
  top: 65%;
  right: -29px;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: #a5a5a5 transparent;
}

.section-main .main-block-3 {
  background: #e5e5e5 url(/s/img/main/main-bg-3-2.jpg) no-repeat center;
  background-size: cover;
  width: 100%;
  padding: 25% 0 25% 0;
  box-sizing: border-box;
  position: relative;
}
.section-main .main-block-3 .text {
  display: inline-block;
  width: 35vw;
  line-height: 2.5vw;
  position: absolute;
  left: 50%;
  top: 33%;
  font-size: 2.7vw;
  color: #ffffff;
  text-shadow: 2px 2px 2px #000000;
}
.section-main .main-block-3 .text span:last-child {
  display: block;
  margin-top: 2vw;
  font-weight: 700;
  font-size: 4.24vw;
  line-height: 4.20vw;
}

.section-main .main-block-4 {
  background: #ffffff url(/s/img/main/main-bg-4-2.png) no-repeat left center;
  background-size: auto 55%;
  background-position-x: 20%;
  width: 100%;
  padding: 25% 0 25% 0;
  box-sizing: border-box;
  position: relative;
}
.section-main .main-block-4 .tube {
  position: absolute;
  height: 94%;
  right: 6%;
  bottom: 1%;
}
.section-main .main-block-4 .text {
  line-height: 3vw;
  position: absolute;
  left: 20%;
  top: 35%;
  font-size: 2.5vw;
  color: #000000;
}
.section-main .main-block-4 .text span:last-child {
  display: block;
  margin-top: 2vw;
  font-weight: 700;
  font-size: 4.74vw;
  line-height: 4.9vw;
}

.section-main .main-block-5 {
  background: #ffffff url(/s/img/main/main-bg-5-4.jpg) no-repeat right center;
  background-size: auto 100%;
  width: 100%;
  padding: 25% 0 25% 0;
  box-sizing: border-box;
  position: relative;
  color: #000000;
  min-height: 460px;
}
.section-main .main-block-5 .btn {
  color: #000000;
  border-color: #000000;
  position: absolute;
  left: 15%;
  bottom: 10%;
}
.section-main .main-block-5 .btn:hover {
  color: #ffffff;
  border-color: #ffffff;
  background-color: #000000;
}
.section-main .main-block-5 .text {
  line-height: 3.5vw;
  position: absolute;
  left: 15%;
  top: 12%;
  font-size: 2.54vw;
  color: #000000;
  font-weight: 700;
}

.section-main .main-block-5 .tx-1 {
  font-weight: normal;
}

.section-main .main-block-5 .tx-2 {
  font-size: 3.74vw;
  margin: 35px 0 0 40px;
  display: flex;
  flex-flow: column;
  gap: 22px 0;
}
.section-main .main-block-5 .tx-3 {
  margin-top: 40px;
  font-weight: 400;
  font-size: 2.04vw;
  line-height: 120%;
  width: 80%;
  text-shadow: 2px 2px 1px #ffffff;
}

.section-main .main-block-6 {
  background: #ffffff url(/s/img/main/main-bg-6-2.jpg) no-repeat center center;
  background-size: cover;
  width: 100%;
  padding: 25% 0 25% 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  color: #ffffff;
  min-height: 460px;
}

.section-main .main-block-6 .text {
  position: absolute;
  left: 15%;
  top: 20%;
  font-size: 3.74vw;
  line-height: 4.5vw;
  font-weight: 700;
  width: 35vw;
}

.section-main .main-block-6 .btn {
  color: #ffffff;
  border-color: #ffffff;
  position: absolute;
  left: 15%;
  bottom: 20%;
}

.section-main .main-block-6 .btn:hover {
  color: #000000;
  border-color: #000000;
  background-color: #ffffff;
}

@media (max-width: 1280px) {
  .section-main .main-block-1 .text {
    top: 20%;
    font-size: 2.54vw;
    line-height: 2.9vw;
  }
  .section-main .main-block-1 .text #prodCount {
    margin-top: 3.7vw;
    font-weight: 700;
    font-size: 11.74vw;
    line-height: 11.5vw;
  }
  .section-main .main-block-1 .text #prodText {
    margin-top: 3.5vw;
    font-weight: 700;
    font-size: 4.9vw;
    line-height: 5.1vw;
  }

  .section-main .main-block-3 .text {
    line-height: 3.4vw;
    position: absolute;
    left: 49%;
    top: 30%;
    font-size: 2.7vw;
    color: #ffffff;
    width: 43vw;
  }
  .section-main .main-block-3 .text span:last-child {
    display: block;
    margin-top: 2.2vw;
    font-size: 4.24vw;
    line-height: 4.20vw;
  }
}

@media (max-width: 1100px) {
  .section-main .main-block-5 .btn {
    left: 15%;
    bottom: 20px;
  }
}

@media (max-width: 800px) {
  .section-main .main-block-2 {
    padding: 50% 0 50% 0;
    background-position-x: 106%;
  }
  .section-main .main-block-2 .name {
    width: 50px;
    left: 11%;
    bottom: 30%;
    font-size: 9vw;
    line-height: 9vw;
  }
  .section-main .main-block-2 .quote {
    width: 45vw;
    font-size: 3vw;
    left: 5%;
    bottom: 59%;
    line-height: 4vw;
  }

  .section-main .main-block-3 {
    padding: 50% 0 50% 0;
    background-position-x: 44%;
  }
  .section-main .main-block-3 .text {
    line-height: 4.7vw;
    left: 48%;
    top: 28%;
    font-size: 3.9vw;
    width: 50vw;
  }
  .section-main .main-block-3 .text span:last-child {
    margin-top: 8.8vw;
    font-size: 7.24vw;
    line-height: 7.50vw;
  }

  .section-main .main-block-4 {
    background-size: auto 38%;
    background-position-x: 10%;
    padding: 50% 0 50% 0;
  }
  .section-main .main-block-4 .text {
    line-height: 5.5vw;
    left: 7%;
    font-size: 4.14vw;
    max-width: 50%;
    top: 33%;
  }
  .section-main .main-block-4 .text span:last-child {
    margin-top: 2vw;
    font-size: 6.74vw;
    line-height: 6.5vw;
  }
  .section-main .main-block-4 .tube {
    height: 94%;
    right: -9%;
  }

  .section-main .main-block-5 {
    padding: 40% 0 40% 0;
  }

  .section-main .main-block-5 .btn {
    left: 10%;
    bottom: 14%;
    text-shadow: 1px 1px 1px #ffffff;
    -webkit-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 1);
    -moz-box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 1);
    box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 1);
  }
  .section-main .main-block-5 .text {
    left: 10%;
  }

  .section-main .main-block-5 .tx-1 {
    font-size: 3.54vw;
    line-height: 4.5vw;
  }

  .section-main .main-block-5 .tx-2 {
    font-size: 4.3vw;
  }

  .section-main .main-block-5 .tx-3 {
    font-size: 2.54vw;
    line-height: 130%;
  }

  .section-main .main-block-6 {
    background-position-x: 65%;
  }

  .section-main .main-block-6 .text {
    left: 10%;
    top: 10%;
    line-height: 120%;
    font-size: 45px;
    width: 60vw;
    text-shadow: 1px 1px 1px #000000;
  }
  .section-main .main-block-6 .btn {
    left: 10%;
  }
}

@media (max-width: 640px) {
  .section-main .main-block-1 .text {
    top: 15%;
    font-size: 20px;
    line-height: 25px;
  }
  .section-main .main-block-1 .text #prodCount {
    margin-top: 25px;
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
  }
  .section-main .main-block-1 .text #prodText {
    margin-top: 23px;
    font-weight: 700;
    font-size: 35px;
    line-height: 37px;
  }

  .section-main .main-block-5 .tx-2 {
    font-size: 5vw;
  }

  .section-main .main-block-5 .btn_tr {
    height: 3.2rem;
    line-height: 3.2rem;
    font-size: 0.75rem;
    padding: 0 1.3rem;
    bottom: 11%;
  }

  .section-main .main-block-6 {
    background-position-x: 75%;
  }

  .section-main .main-block-6 .text {
    width: 75vw;
  }
}

@media (max-width: 480px) {
  .section-main .main-block-1 .text {
    left: 9%;
  }
  .section-main .main-block-1 .text #prodCount {
    font-size: 61px;
  }

  .section-main .main-block-2 {
    padding: 70% 0 70% 0;
    background-position-x: 100%;
    background-size: auto 70%;
  }
  .section-main .main-block-2 .quote {
    width: 90vw;
    font-size: 3.6vw;
    left: 5%;
    bottom: 80%;
    line-height: 4.9vw;
    padding: 4vw 4.6vw;
  }
  .section-main .main-block-2 .name {
    left: 9%;
    bottom: 54%;
  }
  .section-main .main-block-2 .quote:after {
    -moz-transform: skewX(10deg);
    -ms-transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    transform: skewX(10deg);
    top: 100%;
    right: 50%;
    border-width: 30px 0 0 30px;
  }

  .section-main .main-block-3 {
    padding: 70% 0 70% 0;
    background-position-x: 40%;
  }
  .section-main .main-block-3 .text {
    top: 22%;
    left: 22%;
    font-size: 6.5vw;
    line-height: 8.5vw;
    width: 65vw;
  }
  .section-main .main-block-3 .text span:last-child {
    margin-top: 8vw;
    font-size: 9.24vw;
    line-height: 12.5vw;
  }

  .section-main .main-block-4 {
    padding: 70% 0 70% 0;
    background-position: center top;
    background-position-y: 15%;
    background-size: 84% auto;
  }
  .section-main .main-block-4 .text {
    line-height: 6.4vw;
    left: 20%;
    top: 14%;
    font-size: 5.34vw;
    max-width: 90%;
  }
  .section-main .main-block-4 .text span:last-child {
    margin-top: 2.5vw;
    font-size: 9.74vw;
    line-height: 7.5vw;
  }
  .section-main .main-block-4 .tube {
    height: 61%;
    right: 20%;
  }

  .section-main .main-block-5 .tx-1 {
    font-size: 17px;
    line-height: 22px;
  }

  .section-main .main-block-5 .tx-2 {
    font-size: 23px;
    line-height: 23px;
  }

  .section-main .main-block-5 .tx-3 {
    font-size: 14px;
    line-height: 130%;
  }

  .section-main .main-block-5 .btn_tr {
    bottom: 10%;
    font-weight: bold;
  }

  .section-main .main-block-6 {
    background-position-x: 81%;
  }

  .section-main .main-block-6 .text {
    font-size: 40px;
    width: 88vw;
  }

  .section-main .main-block-6 .btn {
    text-shadow: 1px 1px 1px #000000;
    -webkit-box-shadow: 2px 2px 2px 0px #000000;
    -moz-box-shadow: 2px 2px 2px 0px #000000;
    box-shadow: 1px 1px 1px 0px #000000;
    font-weight: bold;
  }
}
