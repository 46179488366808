.form-group{
	margin-bottom: 0;
	font-size: 1.6rem;
	letter-spacing: .05rem;
	&.form-buttons{
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 568px) {
			flex-direction: column;
			a + button{
				margin-top: 1.6rem;
			}
		}
	}
}

input,textarea{
	padding: 0;
	width: 100%;
	border: none;
	outline: none;
	height: 2.2rem;
	line-height: 1;
	font-weight: 400;
	border-radius: 0;
	font-size: 1.2rem;
	letter-spacing: .15rem;
	color: $cornflowerBlue;
	border-bottom: 1px solid $frenchGray;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&::-webkit-input-placeholder {
		transition: opacity 0.3s ease;
	}
	&:-moz-placeholder {
		transition: opacity 0.3s ease;  
	}
	&::-moz-placeholder {    
		transition: opacity 0.3s ease;  
	}
	&:-ms-input-placeholder {  
		transition: opacity 0.3s ease;  
	}
	&:focus::-webkit-input-placeholder{
		opacity: 0;
	}
	&:focus::-moz-placeholder{
		opacity: 0;
	}
	&:focus:-moz-placeholder{
		opacity: 0;
	}
}

textarea{
	min-width: 100%;
	max-width: 100%;
	min-height: 8rem;
	padding-top: .8rem;
	padding-bottom: .8rem;
}


.input-group{
	position: relative;
	padding-top: 2.5rem;
	input, textarea{
		&:focus{
			& ~ .input-group__placeholder{
				top: 0;
				left: .2rem;
				bottom: auto;
				font-size: 1rem;
				@media (max-width: $sw-xs-max) {
					font-size: 1.2rem;
				}
			}
		}
		&:not(:focus):valid{
			& ~ .input-group__placeholder{
				top: 0;
				left: .2rem;
				bottom: auto;
				font-size: 1rem;
				@media (max-width: $sw-xs-max) {
					font-size: 1.2rem;
				}
			}
		}
	}	
	.input-group__placeholder{
		left: 0;
		right: 0;
		top: 2.5rem;
		bottom: 1px;
		color: #b2b2c3;
		font-weight: 300 !important;
		font-size: 1.2rem;
		position: absolute;
		line-height: 2.2rem;
		pointer-events: none;
		letter-spacing: .15rem;
		transition: all 0.3s ease;
		text-transform: uppercase;
		@media (max-width: $sw-xs-max) {
			font-size: 1.4rem;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
		}
	}
}


.form-check{
	margin: 0;
	padding: 0;
}


.check-box{
	input{
		display: none;
		&:checked + span{
			&:after{
				opacity: 1;
			}
			&:before{
				background-color: #40aa3e;
			}
		}
	}
	span{
		font-size: 1.6rem;
		position: relative;
		line-height: 1.6rem;
		padding-left: 2.4rem;
		display: inline-block;
		&:before{
			top: 0;
			left: 0;
			content: '';
			width: 1.6rem;
			height: 1.6rem;
			display: block;
			position: absolute;
			border-radius: .2rem;
			border: 1px solid #D2D2D2;
		}
		&:after{
			top: .1rem;
			opacity: 0;
			left: .5rem;
			content: '';
			width: .6rem;
			height: 1.1rem;
			position: absolute;
			transform: rotateZ(40deg);
			border-right: .2rem solid #fff;
			border-bottom: .2rem solid #fff;
		}
		i{
			display: block;
			font-style: normal;
		}
	}

	input[type="radio"]{
		& + span{
			&:before{
				border-radius: 50%;
				border: 1px solid #979797;
			}
			&:after{
				top: .4rem;
				left: .4rem;
				width: .8rem;
				border: none;
				height: .8rem;
				border-radius: 50%;
				background-color: #fff;
			}
			i.gray{
				color: #9B9B9B;
			}
		}
	}

	&_flt{
		input{
			&:checked + span{
				&:before{
					border: 2px solid #F5A623;
					background-color: transparent;
				}
			}
		}
		span{
			&:before{
				border: 1px solid #979797;
			}
			&:after{
				border-right: .2rem solid #000;
				border-bottom: .2rem solid #000;
			}
			i{
				color: #9B9B9B;
				font-style: normal;
				margin-left: .8rem;
			}
		}
	}

	&_color{
		label{
			display: block;
		}
		input{
			&:checked + span{
				&:before{
					background-color: transparent;
				}
			}
		}
		span{
			width: 2.4rem;
			height: 2.4rem;
			display: block;
			&:before{
				display: none;
				width: 2.4rem;
				height: 2.4rem;
				border-radius: 0;
				border: 2px solid #DCDCDC;
			}
			&:after{
				top: 0.3rem;
				left: 0.8rem;
				width: 0.8rem;
				height: 1.4rem;
				border-right: .2rem solid #fff;
				border-bottom: .2rem solid #fff;
			}
		}
		&-wh{
			span{
				&:before{
					display: block;
				}
				&:after{
					border-right: .2rem solid #000;
					border-bottom: .2rem solid #000;
				}
			}
		}
	}

}


.text-check-box{
	label{
		display: block;
		input{
			display: none;
			&:checked + span{
				background-color: #F4F4F4;
			}
		}
		span{
			display: block;
			font-weight: 600;
			font-size: 1.4rem;
			padding-top: .9rem;
			text-align: center;
			padding-left: 1.2rem;
			padding-right: 1.2rem;
			padding-bottom: .9rem;
			text-transform: uppercase;
			border: 1px solid #D2D2D2;
		}
	}
}







.assessment-box{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.assessment-box__value{
	display: none;
}

.assessment-box__label{

}

.assessment-box__content{
	display: flex;
	align-items: center;
	label{
		display: block;
		input{
			display: none;
		}
		span{
			color: #EC9C16;
			&:first-of-type{
				display: block;
			}
			&:last-of-type{
				display: none;
			}
		}
		&.active{
			span{
				&:first-of-type{
					display: none;
				}
				&:last-of-type{
					display: block;
				}
			}
		}
	}

	&:hover{
		label{
			span{
				&:first-of-type{
					display: none;
				}
				&:last-of-type{
					display: block;
				}
			}
			&:hover{
				& ~ label{
					span{
						&:first-of-type{
							display: block;
						}
						&:last-of-type{
							display: none;
						}
					}
				}
			}
		}
	}

}












.select-box{
	width: 100%;
	position: relative;
	display: inline-block;
	&.active{
		.select-box__button{
			button{
				&:after{
					margin-top: -.3rem;
					transform: rotateZ(-135deg);
				}
			}
		}
		.select-box__list{
			display: block;
		}
	}

	&_inline{
		width: auto;
		&.active{
			.select-box__button{
				button{
					&:after{
						margin-top: -0.2rem;
					}
				}
			}
		}
		.select-box__button{
			button{
				border: none;
				padding-top: 0;
				padding-left: 0;
				font-weight: 400;
				padding-bottom: 0;
				padding-right: 1.2rem;
				background-color: transparent;
				&:after{
					right: 0;
					width: .6rem;
					height: .6rem;
					margin-top: -.5rem;
					border-right: .1rem solid #000;
					border-bottom: .1rem solid #000;
				}
			}
		}
		.select-box__list{
			margin-top: 0;
		}
	}

	&_bd{
		.select-box__button{
			button{
				font-weight: 700;
				font-size: 1.6rem;
				letter-spacing: .05rem;
			}
		}
	}

	&_ip{
		.select-box__button{
			button{
				font-weight: 400;
				padding-top: .8rem;
				padding-bottom: .8rem;
			}
		}
	}



	&__button{
		button{
			width: 100%;
			display: flex;
			text-align: left;
			font-weight: 600;
			position: relative;
			align-items: center;
			padding-top: 1.4rem;
			padding-right: 2rem;
			padding-left: 1.2rem;
			padding-right: 4.8rem;
			padding-bottom: 1.4rem;
			letter-spacing: .04rem;
			background-color: #fff;
			border: 1px solid #D2D2D2;
			span,i,img{
				display: block;
			}
			i + span{
				margin-left: .5rem;
			}
			&:after{
				top: 50%;
				content: '';
				width: .8rem;
				right: 2.1rem;
				height: .8rem;
				display: block;
				position: absolute;
				margin-top: -.7rem;
				transform: rotateZ(45deg);
				border-right: .2rem solid #000;
				border-bottom: .2rem solid #000;
			}
		}
	}
	&__list{
		left: 0;
		right: auto;
		top: 100%;
		z-index: 1;
		display: none;
		min-width: 100%;
		position: absolute;
		margin-top: -.1rem;
		padding-top: 1.6rem;
		margin-right: -100vw;
		padding-bottom: .8rem;
		background-color: #fff;
		border: 1px solid #D2D2D2;
		ul{
			li{
				font-size: 1.6rem;
				line-height: 2.4rem;
				letter-spacing: .05rem;
				&:not(Llast-child){
					margin-bottom: 1.6rem;
				}
				a{
					color: #000;
					display: flex;
					align-items: center;
					white-space: nowrap;
					padding-left: 1.2rem;
					padding-right: 1.2rem;
					text-decoration: none;
					@media (min-width: $sw-md-min) {
						&:hover{
							color: #3e6ce3;
						}
					}
					i,span{
						flex-shrink: 0;
						display: block;
					}
					i + span{
						margin-left: .5rem;
					}
					img {
						display: block;
					}
				}
			}
		}
	}
}


.text-checks{
	width: 100%;
	display: flex;
	margin: -.8rem;
	flex-wrap: wrap;
	.text-checks__element{
		padding: .8rem;
	}
}

.text-radio-box{
	label{
		display: block;
		input{
			display: none;
			&:checked + span{
				color: #000;
				i:after{
					opacity: 1;
				}
			}
		}
		span{
			height: 4rem;
			color: #9B9B9B;
			display: block;
			font-size: 1.6rem;
			text-align: center;
			line-height: 3.8rem;
			padding-left: 1.2rem;
			padding-right: 1.2rem;
			letter-spacing: .05rem;
			border: .1rem solid #D2D2D2;
			i{
				font-style: normal;
				position: relative;
				&:after{
					top: 50%;
					opacity: 0;
					right: 100%;
					content: '';
					width: 0.8rem;
					height: 1.6rem;
					display: block;
					position: absolute;
					margin-top: -1rem;
					margin-right: 1.6rem;
					transform: rotateZ(45deg);
					border-right: .2rem solid #000;
					border-bottom: .2rem solid #000;
					@media (max-width: $sw-xs-max) {
						margin-right: 1rem;
					}
				}
			}
		}
	}
}


 /* ///  DECORATION CSS ///  */
 .cont_select_center {
  position: absolute;
  left: 50%;
  top:50%;
  margin-top: -30px;
  margin-left: -150px;
}

.cont_heg_50 {
  position: absolute;
  height: 50%;
  left: 0;
  top: 0;
  width: 100%;
	background-color: #fd7b52;
}
/* ///  END DECORATION CSS  ///  */

.icon_select_mate {
  position: absolute;
  top:20px;
  right: 2%;
  font-size: 16px;
    height: 22px;
  transition: all 275ms;
}

.select_mate {
  position: relative;
  float: left;
  min-width: 300px;
  width: 100%;
  min-height: 20px;
  color: #1c1c1f;
  font-weight: 300;
  background-color: #fff;
  border-radius: 0;
	transition: all 375ms ease-in-out;
	border-bottom: 1px solid #b2b2c3;

/* Oculto el elemento select */
  select {
  position: absolute;
  overflow: hidden;
  height: 0px;
  opacity: 0;  
    z-index: -1;
  }
}

.cont_list_select_mate {
  position: relative;
  width: 100%;
}

.cont_select_int {
 	position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
  overflow-y: scroll;
  height: 0px;
  width: 100%;
  background-color: #fff;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
	transition: all 375ms ease-in-out;
	list-style-type: none;
	max-height: 265px;
	
	li {
		position: relative;
    width: 100%;
    background-color: #fff;
    list-style-type: none;
		padding: 5px 2%;
		padding-left: 25px;
    margin: 0px;
    transition: all 275ms ease-in-out;
    display: block;
    cursor:pointer;
		
		&:last-child {
        border-radius: 3px;
        border-bottom:0px; 
        }
         &:hover {
		background-color: #EEEEEE;
		 } 
     }

   .active {
   background-color: #f2f2f7;;
   }

}

/* etiqueta <p> con la opcion selecionada  */
.selecionado_opcion {
    padding: 2px 2%;
    width: 100%;
    display: block;
		margin: 0px;
		padding-left: 0;
		cursor: pointer;
		margin-right: 0;
		color: #3e3e48;
		font-size: 12px;
		font-weight: 300;
		line-height: 25px;
		text-transform: uppercase;
		letter-spacing: 1.2px;
}

.select_mate {
	position: relative;
	margin-bottom: 25px;
	&:after {
		content: '';
		display: block;
		background-image: url('../img/profile/chevron-sel.svg');
		background-repeat: no-repeat;
		background-size: 11px 8px;
		width: 11px;
		height: 8px;
		position: absolute;
		top: 55%;
		right: 11px;
		transform: translate(0, -50%);
		pointer-events: none;
		transition: all 0.3s ease;
}
	&:before {
		content: '';
		display: block;
		background-image: url('../img/profile/chevron-sel.svg');
		background-repeat: no-repeat;
		background-size: 11px 8px;
		width: 11px;
		height: 8px;
		position: absolute;
		top: 25%;
		right: 11px;
		transform: translate(0, -50%) rotatex(-180deg);
		pointer-events: none;
		transition: all 0.3s ease;
}
}