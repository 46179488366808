.icon {
	width: 1em;
	height: 1em;
	stroke-width: 0;
	fill: currentColor;
	stroke: currentColor;
	display: inline-block;
	&-logo{
		width: 3.1875em;
	}
	&-cart{
		width: 1.125em;
	}
	&-open{
		width: 0.75em;
	}
	&-lock{
		width: 1.3em;
		height: 1.3em;
	}
	&-logout{
		width: 1.5em;
		height: 1.5em;
	}
	&-logo-vr{
		width: 1.032258064516129032258064516129em;
	}
}

.header__action a svg.icon-logout {
	left: 3px;
}