.ticker {
    &-wrapper {
        width: 100%;
        box-shadow: inset 0 7px 5px rgba(0, 0, 0, 0.16), inset 0 -7px 5px rgba(0, 0, 0, 0.16);
        background-color: #2bbbb3;
        padding: 2rem 16rem;
        margin-bottom: 2.6rem;
        position: relative;
        margin-top: -1px;
        @media (max-width: $sw-md-max) {
            padding: 2rem 6rem 2rem 7rem;
        }
        @media (max-width: $sw-sm-max) {
            padding: 2rem 1rem 2rem 1rem;
        }
    }
    &-container {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    &-inner {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        @media (max-width: $sw-sm-max) {
            flex-wrap: wrap;
        }
    }
    &-side {
        display: flex;
        align-items: center;
        &-btn {
            flex-shrink: 0;
            @media (max-width: $sw-sm-max) {
                width: 100%;
                max-width: 320px;
                margin: 2.5rem auto 0 auto;
                justify-content: space-between;
            }
            .ticker-close {
                display: none;
                color: #ffffff !important;
                font-size: 1rem;
                font-weight: 400;
                line-height: 2.4rem;
                text-decoration: underline;
                text-transform: uppercase;
                letter-spacing: 2px;
                @media (max-width: $sw-sm-max) {
                    display: block;
                }
            }
        }
    }
    &-text {
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        padding: 0 3rem 0 0;
        margin: 0;
        @media (max-width: $sw-md-max) {
            font-weight: 400;
        }
        @media (max-width: $sw-sm-max) {
            font-size: 12px;
            line-height: 18px;
            margin: 0;
            padding: 0 1.5rem;
            text-align: center;
        }
    }
    &-btn {
        width: 235px;
        height: 40px;
        border: 2px solid #ffffff;
        outline: none;
        color: #ffffff !important;
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 2.4px;
        text-decoration: none !important;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: color .3s ease, border-color .3s ease;
        @media (max-width: $sw-sm-max) {
            width: 210px;
            font-size: 1rem;
        }
        &:hover {
            @media (min-width: $sw-sm-min) {
                color: rgba(255,255,255,.8);
                border-color: rgba(255,255,255,.8);
            }
            @media (max-width: $sw-sm-max) {
                color: #fff !important;
            }
        }
    }
}
button.ticker-close {
    cursor: pointer;
    flex-shrink: 0;
    margin-left: 70px;
    // position: absolute;
    // right: 0;
    // top: 50%;
    // transform: translateY(-50%);
    @media (max-width: $sw-sm-max) {
        display: none;
    }
    .icon-close {
        color: #fff;
        font-size: 1.8rem;
    }
}