.s-short-order {
  border-top: 1px solid $athensGray;
  margin-top: 6rem;
  margin-bottom: 5rem;
  @media (max-width: $sw-lg-max) {
    margin-top: 0;
  }

  &__wrapper {
    width: 100%;
    max-width: 67.7%;
    margin: 0 auto;
    @media (max-width: $sw-md-max) {
      max-width: 89.7%;
    }
    @media (max-width: $sw-sm-max) {
      max-width: 100%;
    }
    .hint {
      display: block;
      margin-bottom: 26px;
      color: #009933;
      @media (max-width: $sw-md-min) {
        margin: 0 2px 26px 2px;
      }
    }
    .hint span:first-child {
      font-weight: bold;
    }
  }

  &__error {
    text-align: center;
    color: red;
    height: 30px;
    margin-top: 10px;
  }

  &__head {
    padding: 1.4rem 3rem 0 3rem;
    @media (max-width: $sw-lg-max) {
      padding: 1.4rem 3rem 0 3rem;
    }
    @media (max-width: $sw-md-max) {
      padding: 1rem 1rem 0 1.3rem;
    }
    @media (max-width: $sw-sm-max) {
      padding: 7rem 1rem 0 2rem;
    }
    @media (max-width: $sw-xx-max) {
      display: none;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-span {
      color: #b2b2c3;
      font-size: 1rem;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    &-number {
      max-width: 11%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        display: none;
      }

    }

    &-name {
      max-width: 28.3%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        max-width: 49%;
      }
    }

    &-price {
      max-width: 13.7%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        display: none;
        max-width: 100%;
      }
      @media (max-width: $sw-xx-max) {
        max-width: 100%;
      }
    }

    &-count {
      max-width: 13.5%;
      width: 100%;

      @media (max-width: $sw-sm-max) {
        max-width: 33%;
      }
    }

    &-procent {
      max-width: 12.6%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        max-width: 82px;
      }
    }

    &-income {
      max-width: 13%;
      width: 100%;

      @media (max-width: $sw-sm-max) {
        display: none;
        max-width: 100%;
      }
    }

    &-cost {
      max-width: 8.9rem;
      width: 100%;
      @media (max-width: $sw-lg-max) {
        max-width: 9.3rem;
      }
      @media (max-width: $sw-md-max) {
        max-width: 8.9rem;
      }
      @media (max-width: $sw-sm-max) {
        display: none;
      }
    }
  }

  &__item {
    border: 2px solid $white;
    background-color: #e1e1e6;
    @media (max-width: $sw-xx-max) {
      border-left: 0;
      border-right: 0;
    }

    &-disabled {
      background-color: $athensGray;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5rem 1.3rem 1rem 3.1rem;
      @media (max-width: $sw-lg-max) {
        padding: 0.5rem 1.3rem 1rem 3.1rem;
      }
      @media (max-width: $sw-md-max) {
        padding: 0.5rem 1.3rem 1rem 3.2rem;
      }
      @media (max-width: $sw-sm-max) {
        flex-wrap: wrap;
        align-items: center;
        padding: 3.3rem 1.3rem 3rem 2rem;
      }
      @media (max-width: $sw-xx-max) {
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 3.3rem 0.5rem 3rem 0.5rem;
      }
    }

    &-number {
      max-width: 3%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        display: none;
      }

      span {
        color: $stormGray;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
      }
    }

    &-img {
      max-width: 7.4%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        display: none;
      }

    }

    &-img-wrap {
      width: 6rem;
      height: 6rem;
      background-color: $white;
      padding-top: 4px;
      padding-bottom: 2px;
      text-align: center;

      a {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;

        img {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
        }
      }
    }

    &-name {
      display: flex;
      flex-direction: column;

      span {
        color: $stormGray;
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: uppercase;
        padding-right: 1rem;
        @media (max-width: $sw-sm-max) {
          font-size: 1.4rem;
          line-height: 18px;
          letter-spacing: 1.4px;
        }
      }
    }

    &-desc {
      span {
        color: $stormGray;
        letter-spacing: 0.6px;
        line-height: 25px;
        font-size: 1.2rem;
        padding-right: 1rem;
      }
    }

    &-tags {
      display: flex;
      gap: 0 16px;
      margin-top: 1rem;
      div {
        color: $stormGray;
        font-size: 10px;
        font-weight: 400;
        height: 18px;
        line-height: 18px;
        border-radius: 9px;
        background-color: $llGray;
        border: 1px solid $frenchGray;
        padding: 0 10px;
      }
    }

    &-name-desc {
      max-width: 30.7%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        max-width: 43%;
      }
      @media (max-width: $sw-xx-max) {
        max-width: 55%;
      }

      a {
        text-decoration: none;
      }
    }

    &-price {
      max-width: 11%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        order: 1;
        max-width: 43%;
        padding-top: 29px;
      }
      @media screen and (max-width: 992px) {
        max-width: 47%;
      }
      @media screen and (max-width: 567px) {
        max-width: 43%;
      }
      @media (max-width: $sw-xx-max) {
        width: 100%;
        max-width: 60%;
        min-width: 110px;
        text-align: right;
      }

      span {
        white-space: nowrap;
        color: $stormGray;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        @media (max-width: $sw-sm-max) {
          font-size: 2.4rem;
        }
      }

      .s-short-order__head-price {
        display: none;
        margin-bottom: 7px;

        span {
          color: $charade;
          font-size: 1rem;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        @media (max-width: $sw-sm-max) {
          display: block;
        }
      }
    }

    &-count {
      max-width: 16.3%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        max-width: 45%;
      }
      @media (max-width: $sw-xx-max) {
        max-width: 45%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &-out-of-stock {
        .plus {
          pointer-events: none;
          cursor: default;
        }
      }
    }

    &-count-wrap {
      width: 8.3rem;
      height: 2.5rem;
      padding: 4px;
      border-radius: 13px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-text {
        text-align: center;
        min-width: 7px;
        max-width: 2.5rem;
        width: 100%;
        border: none;
        color: $stormGray;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: -0.06px;
        margin: 0;
        background-color: transparent;
        @media (max-width: $sw-sm-max) {
          font-size: 2.8rem;
          max-width: 7rem;
        }
      }

      .plus, .minus {
        position: relative;
        width: 17px;
        height: 17px;
        border-radius: 9px;
        background-color: $athensGray;
        transition: all 0.3s ease;
        @media (max-width: $sw-sm-max) {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        &:hover {
          background-color: $stormGray;

          &::after, &::before {
            background-color: #fff;
          }
        }
      }

      .plus::after, .minus::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 7px;
        height: 1px;
        background-color: $stormGray;
        transition: all 0.3s ease;
        @media (max-width: $sw-sm-max) {
          width: 12px;
          height: 2px;
        }
      }

      .plus::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
        width: 7px;
        height: 1px;
        background-color: $stormGray;
        transition: all 0.3s ease;
        @media (max-width: $sw-sm-max) {
          width: 12px;
          height: 2px;
        }
      }

      @media (max-width: $sw-sm-max) {
        width: 14rem;
        height: 4.2rem;
        border-radius: 21px;
      }

    }

    &-procent {
      max-width: 13%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        max-width: 5rem;
        width: auto;
      }
      @media (max-width: $sw-xx-max) {
        width: 100%;
        padding-top: 2.9rem;
        max-width: 40%;
      }

      span {
        color: $stormGray;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        white-space: nowrap;
        @media (max-width: $sw-sm-max) {
          font-size: 2.4rem;
          letter-spacing: 2.8px;
        }
      }
    }

    &-income {
      max-width: 12.8%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        order: 4;
        max-width: 9rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 2.1rem;
      }

      span {
        white-space: nowrap;
        color: $stormGray;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        @media (max-width: $sw-sm-max) {
          font-size: 2.4rem;
        }
      }

      .s-short-order__head-income {
        display: none;
        margin-bottom: 7px;

        span {
          color: #b2b2c3;
          font-size: 1rem;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 2px;
          @media (max-width: $sw-xx-max) {
            white-space: nowrap;
          }
        }

        @media (max-width: $sw-sm-max) {
          display: block;
        }
        @media (max-width: $sw-xx-max) {
          max-width: 9rem;
        }
      }
    }

    &-cost {
      max-width: 10%;
      width: 100%;
      @media (max-width: $sw-sm-max) {
        order: 3;
        padding-top: 2.1rem;
        max-width: 36%;
      }
      @media (max-width: $sw-xx-max) {
        max-width: 60%;
      }

      span {
        white-space: nowrap;
        color: $stormGray;
        font-size: 1.2rem;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-weight: 900;
        @media (max-width: $sw-sm-max) {
          font-size: 2.4rem;
          font-weight: 700;
        }
        .rouble{
          font-weight: 700;
          font-size: initial;
        }
      }

      .s-short-order__head-cost {
        display: none;
        margin-bottom: 7px;

        span {
          color: #b2b2c3;
          font-size: 1rem;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        @media (max-width: $sw-sm-max) {
          display: block;
        }
      }
    }

    &-disabled {
      .s-short-order__item-img-wrap img {
        opacity: 0.1;
      }

      .s-short-order__item-procent span {
        opacity: 0.3;
      }

      .s-short-order__item-income span {
        opacity: 0.3;
      }

      .s-short-order__item-cost span {
        opacity: 0.3;
      }

      .s-short-order__item-count-wrap .form-text {
        opacity: 0.3;
      }

      .minus, .plus {
        pointer-events: none;
        cursor: default;
      }

      .s-short-order__item-tags {
        opacity: 0.3;
      }
    }
  }

  &__body {
    padding-top: 1.7rem;
    padding-bottom: 5rem;
    @media (max-width: $sw-sm-max) {
      padding-bottom: 1rem;
    }
  }

  &__total {
    border-top: 1px solid $athensGray;
    border-bottom: 1px solid $athensGray;
    width: 100%;

    &-wrap {
      width: 100%;
      max-width: 67.7%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 20px 0 20px 0;
      //@media (max-width: $sw-lg-max) {
      //	padding: 18px 36px 20px 0;
      //	max-width: 86.5%;
      //}
      @media (max-width: $sw-md-max) {
        padding: 18px 6px 20px 0;
        max-width: 88.5%;
      }
      @media (max-width: $sw-sm-max) {
        padding: 18px 6px 20px 0;
        max-width: 89.5%;
      }
    }

    &-price {
      width: 100%;
      max-width: 30%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-articles {
      width: 100%;
      @media (min-width: $sw-xs-min){
        max-width: 70%;
      }

      span {
        color: #b2b2c3;
        font-size: 1rem;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      b {
        font-weight: 700;
        font-size: 1.4rem;
        color: #b2b2c3;
        letter-spacing: 1.4px;
        margin: 0 5px;
      }
    }

    &-count {
      max-width: 33.3%;
      width: 100%;
      text-align: center;
      @media (max-width: $sw-sm-max) {
        max-width: 50%;
      }

      b {
        color: #2b2b38;
        font-size: 1.8rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 1.8px;
        .rouble{
          font-weight: 700;
          font-size: initial;
        }
      }

      span {
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 2px;
        color: #b2b2c3;
      }

      .total-count {
        font-weight: 700;
        letter-spacing: 1.4px;
        color: #b2b2c3;
        font-size: 14px;
        margin-left: 13.1rem;
        @media (max-width: $sw-lg-max) {
          margin-left: 3.6rem;
        }
      }
    }

    &-price {

      &-income {
        display: inline-block;
        margin: 0 auto;
        @media (max-width: $sw-sm-max) {
          margin-right: 0;
          order:2;
        }

        span {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 2px;
          color: #b2b2c3;
        }

        b {
          font-weight: 700;
          letter-spacing: 1.2px;
          color: #b2b2c3;
          font-size: 14px;
          white-space: nowrap;
        }
      }

      &-cost {
        display: inline-block;
        margin: 0 auto;
        @media (min-width: $sw-sm-min) and (max-width: $sw-sm-max) {
          margin-right: 10em;
          order:1;
        }
        @media (max-width: $sw-sm-min){
          margin-right: 3em;
          order:1;
        }
        b {
          color: #2b2b38;
          font-size: 1.8rem;
          font-weight: 900;
          text-transform: uppercase;
          white-space: nowrap;
          .rouble{
            font-weight: 700;
            font-size: initial;
          }
        }

        span {
          color: #2b2b38;
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }

    @media (max-width: $sw-sm-max) {
      display: none;
    }
  }

  &__total-m {
    display: none;

    .s-short-order__total-price {
      width: auto;
      max-width: 99.9rem;
      @media (max-width: $sw-xx-max) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .s-short-order__total-count {
      width: auto;
      display: flex;

      span {
        font-size: 1.4rem;
        color: #b2b2c3;
        margin-left: 4px;
      }
    }

    .s-short-order__total-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .s-short-order__first-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media (max-width: $sw-xx-max) {
        flex-direction: column;
        align-items: flex-start;
        .total-count {
          margin-left: 0;
        }
      }
    }

    .s-short-order__second-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 11px;

      .s-short-order__total-count {
        b {
          font-size: 2.8rem;
        }
      }

      .s-short-order__total-price-income {
        b, span {
          font-size: 2.4rem;
        }
      }

      .s-short-order__total-price-cost {
        margin-left: 3rem;
        @media (max-width: $sw-xx-max) {
          margin: 0;
        }

        b, span {
          font-size: 28px;
        }
      }
    }

    @media (max-width: $sw-sm-max) {
      display: block;
    }
  }

  &__checkout {
    max-width: 67%;
    width: 100%;
    margin: 0 auto;
    padding: 5.3rem 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: $sw-lg-max) {
      max-width: 67.7%;
    }
    @media (max-width: $sw-md-max) {
      max-width: 89.7%;
    }
    @media (max-width: $sw-sm-max) {
      flex-direction: column-reverse;
    }

    &-clean {
      @media (max-width: $sw-sm-max) {
        margin-top: 4.5rem;
      }

      a {
        cursor: pointer;
        @media (max-width: $sw-sm-max) {
          font-size: 2rem;
          text-transform: uppercase;
        }
      }

      button {
        color: #b2b2c3;
        font-size: 1rem;
        font-weight: 300;
        line-height: 24px;
        text-decoration: underline;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        @media (max-width: $sw-sm-max) {
          font-size: 20px;
        }
      }
    }

    &-issue {
      margin-left: 4.5rem;
      @media (max-width: $sw-sm-max) {
        margin-left: 0;
      }

      button {
        color: #ffffff;
        font-size: 10px;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        background-color: #2b2b38;
        padding: 8px 18px;
        text-align: center;
        position: relative;
        @media (max-width: $sw-lg-max) {
          padding: 8px 23px;
        }
        @media (max-width: $sw-md-max) {
          padding: 8px 21px;
        }
        @media (max-width: $sw-sm-max) {
          padding: 23px 40px;
          font-size: 22px;
          letter-spacing: 3.8px;
        }

        &::after {
          opacity: 0;
          top: -.1rem;
          content: '';
          left: -.1rem;
          right: -.1rem;
          bottom: -.1rem;
          display: block;
          visibility: hidden;
          position: absolute;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          border: 0.1rem solid #ffffff;
        }

        &:hover {
          &::after {
            top: .3rem;
            opacity: 1;
            left: .3rem;
            content: '';
            right: .3rem;
            bottom: .3rem;
            visibility: visible;
          }
        }
      }
    }
  }
}

.s-hort-order__late-order {
  .s-short-order__item-count-span {
    display: block;

    span {
      @media (max-width: $sw-sm-max) {
        font-size: 2.8rem;
      }
    }
  }

  .s-short-order__item-count-wrap {
  }

  .s-short-order__item-count {
    max-width: 16.3%;
    @media (max-width: $sw-sm-max) {
      max-width: 31%;
    }
    @media (max-width: $sw-xx-max) {
      max-width: 26%;
    }
  }

  .s-short-order__item-price {
    max-width: 11%;
    @media (max-width: $sw-sm-max) {
      max-width: 53%;
    }
    @media screen and (max-width: 600px) {
      max-width: 48%;
    }
    @media (max-width: $sw-xx-max) {
      max-width: 25%;
      text-align: right;
    }
  }

  .s-short-order__item-procent {
    @media (max-width: $sw-sm-max) {
      width: 100%;
      max-width: 15%;
    }
    @media (max-width: $sw-xx-max) {
      width: 100%;
      max-width: 60%;
    }
  }

  .s-short-order__item-name-desc {
    @media (max-width: $sw-sm-max) {
      max-width: 53%;
    }
    @media (max-width: $sw-xx-max) {
      max-width: 59%;
    }
  }

  .s-short-order__item-income {
    @media (max-width: $sw-sm-max) {
      align-items: flex-start;
    }
    @media (max-width: $sw-xx-max) {
      max-width: 26%;
      align-items: flex-end;
    }

    span {
      @media (max-width: $sw-sm-max) {
        display: inline-block;
      }
    }
  }

  .s-short-order__item-cost {
    @media (max-width: $sw-sm-max) {
      max-width: 31%;
    }
    @media (max-width: $sw-xx-max) {
      max-width: 60%;
    }
  }
}


.procent {
  display: none;
  font-weight: 400 !important;
  font-size: 2.8rem;
  @media (max-width: $sw-sm-max) {
    display: inline-block;
  }
}

.s-short-order__head-procent-m, .s-short-order__head-name-m,
.s-short-order__head-count-m {
  display: none;
  @media (max-width: $sw-sm-max) {
    display: block;
    margin-bottom: 7px;
    span {
      color: #b2b2c3;
      font-size: 1rem;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
}

.s-short-order__item-wrapper {
  @media (max-width: $sw-xx-max) {
    justify-content: space-between;
  }
}

.s-short-order__head-count-m {
  @media (max-width: $sw-xx-max) {
    text-align: left;
  }
}

.s-hort-order__late-order {
  .s-short-order__item-count {
    @media (max-width: $sw-xx-max) {
      max-width: 28%;
    }

    .s-short-order__item-procent {
      @media (max-width: $sw-sm-max) {
        max-width: 15%;
      }
    }
  }
}

.input-animate {
  animation: bg-animate 1.5s linear;
}

@keyframes bg-animate {
  from {
    background-color: white;
  }
  50% {
    background-color: rgb(45, 216, 96)
  }
  to {
    background-color: white
  }
}
