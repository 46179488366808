.contacts-info {
    margin-top: 2.8rem;
    border-top: 1px solid #f2f2f7;
    padding-top: 6rem;
    &__wrapper {
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        .s-title-box__wrapper {
            padding-top: 0;
            margin-bottom: 0;
        }
        .s-title-box__title h4 {
            margin-top: 0;
        }
    }
    &__box {
        width: auto;
        @media (max-width: $sw-sm-max) {
            width: 50%;
            margin-bottom: 6.9rem;
        }
        @media (max-width: $sw-xs-max) {
            width: 100%;
        }
        &-inner {
            max-width: 210px;
            @media (max-width: $sw-sm-max) {
                max-width: 240px;
            }
            @media (max-width: $sw-xs-max) {
                max-width: 315px;
                margin: 0 auto;
                text-align: center;
            }
        }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            @media (max-width: $sw-sm-max) {
                flex-wrap: wrap;
                max-width: 600px;
                margin: 0 auto 3rem auto;
            }
            @media (max-width: $sw-xs-max) {
                padding: 0 1.5rem;
            }
        }
        &-title, &-text, &-link, span, a {
            display: inline-block;
            width: 100%;
            text-transform: uppercase;
            color: $stormGray;
        }
        a {
            margin-bottom: 3px;
        }
        &-title {
            font-size: 1.4rem;
            font-weight: 400;
            letter-spacing: 1.4px;
            @media (max-width: $sw-sm-max) {
                font-weight: 500;
            }
        }
        &-text, &-link{
            letter-spacing: 2px;
            font-size: 1rem;
            font-weight: 300;
            @media (max-width: $sw-sm-max) {
                font-size: 1.2rem;
            }
            @media (max-width: $sw-xs-max) {
                font-weight: 400;
            }
        }
        &-text {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            .week-schedule {
                margin-bottom: 3px;
                text-align: left;
                white-space: nowrap;
                @media (max-width: $sw-sm-max) {
                    min-width: 60px;
                    max-width: 90px;
                }
            }
        }
        &-link {
            margin-top: 1rem;
            text-decoration: underline;
        }
    }
    &__map {
        margin-bottom: 2rem;
        margin-top: 2.5rem;
        width: 100%;
        height: 440px;
        @media (max-width: $sw-sm-max) {
            max-width: 880px;
            margin: 2.5rem auto 0 auto;
        }
    }
}