.s-remind {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 9.2rem;
	padding-bottom: 19rem;
	@media (max-width: $sw-sm-max) {
		padding-bottom: 14rem;
	}
	&__head {
		h2 {
			color: #3e3e48;
			font-size: 36px;
			font-weight: 900;
			line-height: 25px;
			text-transform: uppercase;
			letter-spacing: 3.6px;
			margin: 0;
		}
	}
	&__form {
		padding-top: 8rem;
		width: 100%;
		max-width: 640px;
		margin: 0 auto;
		@media (max-width: $sw-md-max) {
			max-width: 445px;
			padding-left: 2rem;
			padding-right: 2rem;
		}
		@media (max-width: $sw-xs-max) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	&__error {
		margin: 0 auto;
		max-width: 440px;
		color: red;
	}

	&__login, &__captcha {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		&-form {
			width: 100%;
			.form-control {
				margin: 0;
				padding: 0;
				border: none;
				height: 2.2rem;
				line-height: 1;
				font-weight: 400;
				border-radius: 0;
				font-size: 1.2rem;
				letter-spacing: .15rem;
				outline: none !important;
				box-shadow: none !important;
				background-color: transparent;
				border-bottom: 1px solid #b2b2c3;
			}
		}
		.captcha-image {
			border-bottom: 1px solid #b2b2c3;
			max-width: 200px;
			display: flex;
		}

		.captcha-string {
			max-width: 420px;
		}
	}

	&__captcha {
		padding-top: 20px;
		&__refresh {
			width: 36px;
			height: 36px;
			background-image: url("/s/img/icons/refresh-36x36.png");
			cursor: pointer;
		}
		&__image {
			width: 155px;
			height: 36px;
		}
	}

	&__login {
		&-form {
			max-width: 100%;
		}
	}

	&__sent-block {
		text-align: center;
		font-size: 15px;
		font-weight: 300;
		line-height: 18px;
		display: none;
		span {
			color: #b2b2c3;
			@media (max-width: $sw-sm-max) {
				font-size: 18px;
			}
		}
		a {
			color: #2b2b38;
			text-decoration: underline;
			@media (max-width: $sw-sm-max) {
				font-size: 18px;
			}
		}
	}



	&__submit {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		margin-top: 6rem;
		button {
			padding: 0 3.6rem;
			@media (max-width: $sw-sm-max) {
				font-size: 22px;
				padding: 0 9.8rem;
				height: 8rem;
			}
		}
	}
}
.remind_head {
		display: block;
}

