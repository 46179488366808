@mixin font-face ($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
	@font-face {
		font-family: $fontname;
		src: url('../fonts/' + $fontfile + '.eot');
		src: url('../fonts/' + $fontfile + '.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/' + $fontfile + '.woff') format('woff'),
			 url('../fonts/' + $fontfile + '.ttf') format('truetype'),
			 url('../fonts/' + $fontfile + '.svg##{fontname}') format('svg');
		font-weight: $fontweight;
		font-style: $fontstyle;
	}
};

@include font-face('GothamPro', 'GothamPro', 400, normal);
@include font-face('GothamPro', 'GothamPro-Bold', 700, normal);
@include font-face('GothamPro', 'GothamPro-Light', 300, normal);
@include font-face('GothamPro', 'GothamPro-Black', 900, normal);
@include font-face('GothamPro', 'GothamPro-Medium', 500, normal);

@include font-face('GothamPro', 'GothamPro-Italic', 400, italic);
@include font-face('GothamPro', 'GothamPro-BoldItalic', 700, italic);
@include font-face('GothamPro', 'GothamPro-LightItalic', 300, italic);
@include font-face('GothamPro', 'GothamPro-BlackItalic', 900, italic);
@include font-face('GothamPro', 'GothamPro-MediumItalic', 500, italic);

@include font-face('GothamProNarrow', 'GothamProNarrow-Bold', 700, normal);
@include font-face('GothamProNarrow', 'GothamProNarrow-Medium', 500, normal);