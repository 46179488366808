.alert-message {
  padding: 10px 20px;
  border-bottom: 1px;
  width: 100%;
  display: none;

  &_fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    text-align: center;
    display: flex;
  }

  &_error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &_success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &_warning {
    color: #573f15;
    background-color: #eddfd4;
    border-color: #e6d9c3;
  }

  &__text {
    flex-grow: 1;
  }

  &__close {
    font-size: 17px;
    cursor: pointer;
  }
}
