.specialization__items{
	display: flex;
	align-items: stretch;
	@media (max-width: $sw-xs-max) {
		flex-direction: column;
	}
}

.specialization__item{
	width: 100%;
	position: relative;
	&:not(:first-child){
		&:after{
			top: 0;
			bottom: 0;
			width: 2px;
			left: -1px;
			content: '';
			display: block;
			position: absolute;
			background-color: $white;
			@media (max-width: $sw-xs-max) {
				left: 0;
				right: 0;
				width: auto;
				height: 2px;
				bottom: auto;
			}
		}
	}
}

.specialization-box{
	display: flex;
	padding: 3.6rem;
	min-height: 48rem;
	text-align: center;
	align-items: center;
	background-color: #000;
	flex-direction: column;
	justify-content: center;
	@media (max-width: $sw-md-max) {
		min-height: 48.4rem;
	}
	@media (max-width: $sw-sm-max) {
		padding: 2.6rem;
	}
	@media (max-width: $sw-xs-max) {
		padding: 1.6rem;
		min-height: auto;
		padding-top: 8.1rem;
		padding-bottom: 5.7rem;
	}
}

.specialization-box__view{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	picture{
		width: 100%;
		height: 100%;
	}
}

.specialization-box__name{
	z-index: 1;
	width: 100%;
	max-width: 50rem;
	position: relative;
	padding-top: 1.6rem;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	display: inline-block;
	padding-bottom: 1.8rem;
	border: 0.4rem solid $white;
	h3{
		color: $white;
		font-size: 2.4rem;
		line-height: 2.9rem;
		@media (max-width: $sw-md-max) {
			font-size: 2rem;
			line-height: 2.6rem;
		}
		@media (max-width: 876px) {
			font-size: 1.6rem;
			line-height: 2rem;
		}
		@media (max-width: $sw-xs-max) {
			font-size: 2rem;
			line-height: 2.6rem;
		}
	}
	@media (max-width: $sw-xs-max) {
		max-width: 40rem;
	}
}

.specialization-box__caption{
	z-index: 1;
	width: 100%;
	color: $white;
	max-width: 50rem;
	font-weight: 300;
	font-size: 1.4rem;
	margin-left: auto;
	position: relative;
	margin-top: 2.5rem;
	margin-right: auto;
	line-height: 2.1rem;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
	padding-bottom: 3.3rem;
	text-transform: uppercase;
	text-align: justify;
	@media (max-width: $sw-md-max) {
		max-width: 43rem;
	}
	@media (max-width: $sw-sm-max) {
		max-width: 40rem;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-left: 0;
		max-width: 40rem;
		padding-right: 0;
	}
	@media (max-width: $sw-xx-max) {
		margin-top: 2rem;
		font-size: 1.2rem;
		line-height: 1.8rem;
		padding-bottom: 2.4rem;
	}
	&:after{
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		height: .4rem;
		width: 5.7rem;
		display: block;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		background-color: $white;
	}
}
