.s-card-payment {
	margin-bottom: 5rem;
	&__head {
		width: 100%;
		position: relative;
		padding-top: 7px;
		@media (max-width: $sw-lg-max) {
			padding-top: 0;
		}
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 1px;
			background-color: #f2f2f7;
			width: 100%;
			left: 0;
			right: 0;
		}
		&-desc {
			@media (max-width: $sw-sm-max) {
				display: none;
			}
		}
		&-mobile {
			display: none;
			@media (max-width: $sw-sm-max) {
				display: block;
				margin-bottom: 4.7rem;
			}
		}
	}
	&__head-wrapper {
		text-align: center;
		border-radius: 20px;
		border: 1px solid #f2f2f7;
		background-color: #ffffff;
		position: relative;
		margin: 0 auto;
		max-width: 322px;
		width: 100%;
		padding: 6px 20px;
		span {
			color: #000000; 
			font-size: 10px;
			font-weight: 700;
			line-height: 25px;
			text-transform: uppercase;
			letter-spacing: 2px;
		}
	}
	&__desc {
		padding-bottom: 4rem;
		padding-top: 9.2rem;
		border-bottom: 1px solid #f2f2f7;
		@media (max-width: $sw-lg-max) {
			border: none;
		}
		@media (max-width: $sw-md-max) {
			padding-bottom: 8.5rem;
		}
		@media (max-width: $sw-sm-max) {
		padding-top: 11.2rem;
		}
		&-wrap {
			max-width: 44%;
			width: 100%;
			margin: 0 auto;
			@media (max-width: $sw-lg-max) {
				max-width: 69.2%;
			}
			@media (max-width: $sw-md-max) {
				max-width: 84.7%;
			}
			@media (max-width: $sw-sm-max) {
				max-width: 97.3%;
			}
			p {
				color: #4d4d57;
				font-size: 14px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: -0.07px;
				text-align: justify;
				@media (max-width: $sw-sm-max) {
					font-size: 18px;
					}
			}
		}
	}
	&__btn {
		display: flex;
		justify-content: center;
		padding-top: 5rem;
		// padding-bottom: 10rem;
		@media (max-width: $sw-lg-max) {
			padding-top: 0;
		}
		a {
			padding: 0 4.5rem;
			text-decoration: none;
			@media (max-width: $sw-sm-max) {
				font-size: 22px;
				padding: 0 7.5rem;
				height: 9rem;
				margin: 1rem;
			}
		}
	}
	&__btn-wrapper {
		display: flex;
		max-width: 600px;
		margin: 0 auto;
		justify-content: space-around;
		@media (max-width: $sw-sm-max) {
			display: block;
		}
	}
}
.card-payment_head {
	display: flex;
	flex-direction: column;
	@media (max-width: $sw-sm-max) {
		flex-direction: column-reverse;
	}
}


.pay-result p {
	text-align: center;
	font-size: 16px;
}

.pay-result .success {
	color: #009933;
}

.pay-result .fail {
	color: #dc1f00;
}

.pay-result .link {
	margin-bottom: 100px;
}
