.s-invoice {
	border-top: 1px solid #f2f2f7;
	margin-top: 2.5rem;
	padding-top: 2.5rem;
	@media (max-width: $sw-md-max) {
		margin-top: 0;
		padding-top: 1.5rem;
	}
	@media (max-width: $sw-sm-max) {
		padding-top: 6.5rem;
		padding-bottom: 3.8rem;
	}
	&__wrapper {
		max-width: 67.3%;
		width: 100%;
		margin: 0 auto;
		border-bottom: 1px solid #f2f2f7;
		display: flex;
		justify-content: space-between;
		padding-bottom: 2.5rem;
		@media (max-width: $sw-md-max) {
			max-width: 89.7%;
		}
		@media (max-width: $sw-sm-max) {
			max-width: 100%;
			padding: 0 2rem;
			flex-direction: column;
		}
	}
	&__download-block {
		display: flex;
	}
	&__scoring {
		margin-right: 20px;
	}
	&__title {
		@media (max-width: $sw-sm-max) {
			display: flex;
			flex-direction: column;
		}
		span {
			color: #2b2b38;
			font-size: 10px;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 1px;
			@media (max-width: $sw-sm-max) {
				font-size: 14px;
				margin-bottom: 17px;
			}
		}
		a {
			text-decoration: none;
			margin-left: 10px;
			display: inline-block;
			@media (max-width: $sw-xx-max) {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
			&:hover {
				span {
					color: #2b2b38;
					text-decoration: none;
				}
			}
			@media (max-width: $sw-sm-max) {
				margin-left: 0;
				margin-bottom: 15px;
			}
			span {
				color: #b2b2c3;
				font-size: 10px;
				font-weight: 300;
				text-decoration: underline;
				text-transform: uppercase;
				letter-spacing: 2px;
				transition: all 0.3s ease;
				@media (max-width: $sw-sm-max) {
					font-size: 19px;
					margin-left: 15px;
				}
			}
			img {
				margin-right: 8px;
				@media (max-width: $sw-xx-max) {
					flex-shrink: 0;
				}
				@media (max-width: $sw-sm-max) {
					height: 25px;
					width: 25px;
				}
			}
		}
	}
	&__waybill {
		margin-left: 2rem;
		margin-right: 6rem;
	}
	&__ready-block {
		@media (max-width: $sw-sm-max) {
			display: none;
		}
		span {
			color: #b2b2c3;
			font-size: 10px;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
		&-m {
			display: none;
			margin-top: 30px;
			@media (max-width: $sw-sm-max) {
				display: block;
			}
			span {
				color: #b2b2c3;
				font-size: 14px;
				font-weight: 300;
				text-transform: uppercase;
				letter-spacing: 1.4px;
			}
		}
	}
}
.s-hort-order__late-order {
	.s-short-order {
		border-top: none;
		margin-top: 0;
		padding-bottom: 10.8rem;
		@media (max-width: $sw-md-max) {
			padding-bottom: 0;
		}
	}
	.s-short-order__head {
		@media (max-width: $sw-md-max) {
			padding: 0.8rem 1rem 0 1.3rem;
		}
	}
	.s-short-order__checkout {
		display: none;
	}
	.s-short-order__total-count b {
		color: #b2b2c3;
	}
	.s-short-order__total-price-cost b {
		color: #b2b2c3;
	}
}
.s-invoice__ready-block .paylink, .s-invoice__ready-block-m .paylink{ display: inline-block; margin-left: 30px; }
