.h-object-fit{
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
	display: block;
	> img{
		position: absolute;
		max-height: 100%;
		max-width: 100%;
		margin: auto;
		height: auto;
		width: 100%;
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
		object-position: 50% 50%;
		object-fit: cover;
		max-height: none;
		max-width: none;
		height: 100%;
		.no-object-fit &,
		.no-objectfit & {
			display: none;
		}
	}
}