.s-info-boxes {
    &__wrapper {
        .main__focus {
            &-wrapper {
                width: 100%;
                max-width: 1760px;
                margin: 0 auto;
                display: flex;
                align-items: stretch;
                padding: 2rem 80px 10.6rem 80px;
                @media (max-width: $sw-md-max) {
                    padding: 2rem 50px 12.6rem 50px;
                }
                @media (max-width: $sw-xs-max) {
                    flex-wrap: wrap;
                    padding: 2rem 20px 3rem 20px;
                }
            }
            &-box {
                width: 100%;
                position: relative;
                padding: 0 9.3rem 0 0;
                &:nth-child(3n+3) {
                    padding-right: 0;
                }
                @media (max-width: $sw-md-max) {
                    padding: 0 6.3rem 0 0;
                }
                @media (max-width: $sw-xs-max) {
                    max-width: 380px;
                    margin: 0 auto 61px auto;
                    text-align: center;
                    padding: 0;
                }
            }
            &-title {
                @include h1();
                margin-bottom: 2.7rem;
                display: block;
            }
            &-caption {
                text-align: justify;
                color: $stormGray;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 2.5rem;
                margin-bottom: 0;
                @media (max-width: $sw-md-max) {
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                }
                @media (max-width: $sw-xs-max) {
                    font-size: 1.4rem;
                    line-height: 2rem;
                }
            }
        }
        .recognizability {
            &__wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                max-width: 1760px;
                margin: 0 auto;
                padding: 0 80px 7.4rem 80px;
                @media (max-width: $sw-md-max) {
                    padding: 0 50px 10.5rem 50px;
                }
                @media (max-width: $sw-sm-max) {
                    padding: 0 0 3.5rem 0;
                    max-width: 665px;
                    flex-wrap: wrap;
                }
                @media (max-width: $sw-xs-max) {
                    padding: 0 1.8rem 3.5rem 1.8rem;
                }
            }
            &__item {
                width: 260px;
                @media (max-width: $sw-md-max) {
                    width: 200px;
                }
                @media (max-width: $sw-sm-max) {
                    width: 50%;
                    margin-bottom: 6.9rem;
                    padding-right: 1.5rem;
                    padding-left: 1.5rem;
                }
                @media (max-width: $sw-xx-max) {
                    padding-right: 0;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 4.5rem;
                }
            }
            &__title, &__info {
                display: block;
            }
            &__title {
                color: $stormGray;
                font-size: 1.4rem;
                font-weight: 400;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }
            &__info {
                color: #b2b2c3;
                font-size: 1rem;
                font-weight: 300;
                letter-spacing: 2px;
                text-decoration: underline;
            }
        }
        .principles {
            &__wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                max-width: 1760px;
                margin: 0 auto;
                padding: 3.3rem 80px 4.9rem 80px;
                @media (max-width: $sw-md-max) {
                    padding: 0 50px 4.9rem 50px;
                }
                @media (max-width: $sw-xs-max) {
                    padding: 0 20px 4.9rem 20px;
                }
            }
            &__item {
                display: flex;
                width: calc(100% / 3);
                margin-right: 0;
                padding-right: 4rem;
                margin-bottom: 7.3rem;
                @media (max-width: $sw-md-max) {
                    margin-bottom: 5.5rem;
                    margin-right: 0;
                    width: 50%;
                }
                @media (max-width: $sw-xs-max) {
                    width: 100%;
                    padding-right: 0;
                }
                &:nth-child(3n+3) {
                    margin-right: 0;
                }
                span {
                    display: inline-block;
                    width: 100%;
                }
            }
            &__left-side {
                width: 80px;
                flex-shrink: 0;
                @media (max-width: $sw-xs-max) {
                    width: 65px;
                }
            }
            &__num {
                color: $stormGray;
                font-size: 3.6rem;
                font-weight: 300;
                line-height: 2.5rem;
                letter-spacing: -0.18px;
            }
            &__title {
                text-transform: uppercase;
                color: $stormGray;
                font-size: 1.4rem;
                font-weight: 400;
                letter-spacing: 1.4px;
                line-height: 2rem;
                margin-bottom: 1.4rem;
            }
            &__text {
                text-align: justify;
                font-size: 1.4rem;
                letter-spacing: -0.07px;
                line-height: 2.5rem;
            }
        }
    }
}
