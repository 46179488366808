.s-checkout {
	border-bottom: 1px solid #f2f2f7;
	position: relative;
	padding-bottom: 5rem;
	padding-top: 0;
	
	&__wrapper {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		position: relative;
		@media (max-width: $sw-md-max) {
			max-width: 960px;
			display: flex;
			flex-direction: column;
		}
		@media (max-width: $sw-sm-max) {
			max-width: 90%;
		}
		@media (max-width: $sw-xs-max) {
			max-width: 93%;
		}
		.checkbox-label {
			margin-top: 29px;
			@media (max-width: $sw-xs-max) {
				margin-top: 34px;
			}
		}
	}
	.s-ask-question__form-checkbox {
		position: relative;
	}
	.s-ask-question__form-checkbox-text span, .s-ask-question__form-checkbox-text a {
		@media (max-width: $sw-sm-max) {
			font-size: 9px;
		}
	}
}
.s-checkout__btn {
 margin-bottom: 5rem;
 &-wrap {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
	padding-top: 3rem;
	@media (max-width: $sw-md-max) {
		max-width: 1000px;
	}
	@media (max-width: $sw-sm-max) {
		max-width: 100%;
	}
	.catalog-box__actions {
		@media (max-width: $sw-sm-max) {
		 display: flex;
		 flex-direction: column-reverse !important;
		 align-items: center;
		 justify-content: center;
		 width: 100%;
		 }
	 }
 }
 
.btn_under {
	color: #b2b2c3;
	font-size: 10px;
	font-weight: 300;
	line-height: 24px;
	text-decoration: underline;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	margin-right: 42px;
	@media (max-width: $sw-sm-max) {
		margin-right: 0;
		margin-top: 47px;
		display: inline-block;
		font-size: 22px;
		}
}
.btn_gr {
	@media (max-width: $sw-sm-max) {
		font-size: 22px;
		height: 72px;
		padding: 0 5.6rem;
		line-height: 7.2rem;
		}
}
}
.catalog-box__actions-checkout {
	position: relative !important;
	bottom: 0 !important;
}