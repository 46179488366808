.team__content{
	background-color: $athensGray;
}

.team__items{
	width: 100%;
	display: flex;
	max-width: 50%;
	margin-left: auto;
	margin-right: auto;
	align-items: stretch;
	border-left: 2px solid $white;
	border-right: 2px solid $white;
	@media (max-width: $sw-md-max) {
		border: none;
		max-width: 100%;
	}
	&_list {
		margin-bottom: 80px;
	}
}

.team__items.wide {
	max-width: 100%;
}

.team__items.mt40 {
	margin-top: 40px;
}

.team__items.mb40 {
	margin-bottom: 40px;
}

.team__item{
	width: 50%;
	flex-shrink: 0;
	position: relative;
	border-bottom: 2px solid #ffffff;
	&:not(:first-child){
		&:after{
			top: 0;
			bottom: 0;
			width: 2px;
			left: -1px;
			content: '';
			display: block;
			position: absolute;
			background-color: $white;
		}
	}
	&:first-child{
		.team__item-caption{
			right: 100%;
			text-align: right;
			padding-right: 5rem;
			@media (max-width: $sw-md-max) {
				left: 0;
				right: auto;
				padding-right: 0;
				padding-left: 5rem;
			}
			@media (max-width: $sw-sm-max) {
				right: 0;
				left: auto;
				padding-left: 0;
				padding-right: 2.5rem;
			}
			@media (max-width: $sw-xx-max) {
				padding-right: 1.6rem;
			}
		}
	}
	&:nth-child(2){
		.team__item-caption{
			left: 100%;
			padding-left: 5rem;
			@media (max-width: $sw-md-max) {
				right: 0;
				left: auto;
				padding-left: 0;
				padding-right: 5rem;
			}
			@media (max-width: $sw-sm-max) {
				left: 0;
				right: auto;
				padding-right: 0;
				padding-left: 2.5rem;
			}
			@media (max-width: $sw-xx-max) {
				padding-left: 1.6rem;
			}
		}
	}
}

.team__item.wide {
	width: 100%;
	position: relative;
}

.team__items.wide .name {
	display: block;
	position: absolute;
	color: #ffffff;
	padding: 7px 12px;
    background-color: rgba(0, 0, 0, 0.3);
	font-size: 1.6rem;
	line-height: 2rem;
    letter-spacing: .015rem;
	bottom: 1.5rem;
	left: 1.9rem;
}

.team__items.wide .name.odd {
	left: auto;
	right: 1.9rem;
	text-align: right;
}

@media (max-width: $sw-xs-max) {
	.team__items.wide .name {
		font-size: 1.1rem;
		line-height: 1.5rem;
	}
}

.team__item-view{
	width: 100%;
	height: 32.4rem;
	@media (max-width: $sw-md-max) {
		height: 32rem;
	}
	@media (max-width: $sw-xs-max) {
		height: 20.2rem;
	}
	picture{
		width: 100%;
		height: 100%;
	}
}

.team__item-title {
	font-size: 20px;
	font-weight: bold;
	padding: 30px 40px 30px 40px;
	background-color: #f3f2f8;
}

.team__item-caption {
	top: 0;
	position: absolute;
	line-height: 1.7rem;
	padding-top: 7.6rem;
	@media (max-width: $sw-sm-max) {
		top: auto;
		bottom: 0;
		color: $white;
		font-size: 1.6rem;
		line-height: 2rem;
		padding-bottom: 1.2rem;
		letter-spacing: .015rem;
	}
	@media (max-width: $sw-xx-max) {
		font-size: 1.1rem;
		letter-spacing: 0;
		line-height: 1.5rem;
		padding-bottom: 1rem;
	}

	span {
		display: block;
		@media (max-width: $sw-md-max) {
			color: $white;
			padding: 7px 12px;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}

	&_list {
		@media (max-width: $sw-sm-max) {
			padding-bottom: 2rem;
		}
	}
}

.team-link__wrap {
	font-size: 3rem;
	padding: 2rem 0;
	text-align: center;
}

.team-member-photo {
	height: 100%;
	width: 100%;
	background-size: auto, 324px;
	background-position: center center;
	background-repeat: no-repeat;
}

.team__citation {
	width: 50%;
	margin: 90px auto;
	font-size: 24px;
	font-style: italic;
}

.team__citation .join {
	font-style: normal;
	font-size: 18px;
}

.team__citation q {
	quotes: "“" "”"
}

.team__citation .sign {
	text-align: right;
	font-size: 14px;
	margin-top: 10px;
}


@media (max-width: 1280px) {
	.team__citation {
		width: 80%;
	}
}