.h-reset-list{
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.h-reset{
	h1,h2,h3,h4,h5,h6{
		margin: 0;
	}
}

.h-narrow {
  width: 100%;
  max-width: 640px;
  margin: 30px auto 30px auto;
}