.article {
  max-width: 1040px;
  padding: 0 20px;
  margin: 8rem auto 10rem auto;

  &__title {
    margin-top: 100px;
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    text-transform: none;
  }

  &__box-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__content-text {
    p {
      color: $body-color;
      line-height: 1.9rem;
      letter-spacing: 0;
      margin-bottom: 0;
    }
    .huge-text {
      font-size: 3.4rem;
    }
  }

  &__content-image-wrap {
    margin: 30px 0;

    &_center {
      text-align: center;
    }

    &_right {
      text-align: right;
    }
  }

  &__content-product {
    margin-bottom: 30px;
  }

  &__content-sign {
    color: #4d4d57;
    margin: 50px 0;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  }

  &__image-sign{
    color: #4d4d57;
    font-size: 1.9rem;
    text-align: center;
    font-style: italic;
    margin: 40px 0;
    border: 1px solid $llGray;
    padding: 30px 40px;
  }

  &__quote-line {
    border-bottom: 1px solid $llGray;
    text-align: center;
    > svg {
      position: relative;
      margin-bottom: -20px;
      width: 60px;
      height: 40px;
      padding: 0 20px;
      background-color: white;
    }
  }

  &__social {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
  }

  &__social_icon {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #f2f2f7;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  &__social_icon img {
    width: 26px;
    height: 26px;
  }

  &__album {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px 10px;
    margin: 40px 0;
  }

  &__album img {
    width: 21%;
    aspect-ratio: 3 / 4;
    cursor: pointer;
  }

  #sign-form {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 40;
    background-color: rgba(23,23,23,0.95);
    align-content: center;
    align-items: center;
    justify-content: center;

    h2 {
      margin-top: 0;
    }

    .sign-form-close {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 20px;
      right: 20px;
      color: #232323;
      cursor: pointer;
    }
    .ajax-form {
      background-color: #ffffff;
      padding: 4rem;
      max-width: 600px;
      width: calc(100% - 20px);
      position: relative;
    }
  }

  #sign-form.show {
    display: flex;
  }

}

@media (max-width: 900px) {
  .article {
    &__album img {
      width: 30%;
    }
  }
}

@media (max-width: 600px) {
  .article {
    gap: 30px 10px;
    &__album img {
      width: 45%;
    }
  }
}

.article p {
  line-height: 145%;
  margin-bottom: 16px;
}

.article ul {
  margin: 20px 0;
}

.article li {
  line-height: 145%;
  margin-bottom: 10px;
}

.article li:last-child {
  margin-bottom: 0;
}

.article.mt4 {
  margin-top: 4rem;
}

.article .author {
  display: flex;
  background-color: #f3f2f8;
  padding: 30px 36px;
  gap: 20px 40px;
  margin-bottom: 40px;
}

.article .author .photo {
  width: 120px;
  min-width: 120px;
}
.article .author .photo img {
  width: 100%;
}

.article .author .info {
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 160px;
}

.article .author .info a {
  text-decoration: underline rgba(0, 0, 0, 0);
  transition: all 0.2s ease;
}

.article .author .info a:hover {
  text-decoration: underline rgba(0, 0, 0, 1);
  transition: all 0.2s ease;
}

.article .author .info .description {
  font-size: 18px;
  line-height: 150%;
  font-weight: normal;
}

.article .author .info .city {
  font-size: 22px;
  line-height: 22px;
  font-weight: normal;
}

.vbox-overlay .vbox-container {
    overflow: hidden;
}

.vbox-overlay .vbox-close {
    height: 41px;
    right: -10px;
}

.vbox-overlay .vbox-prev, .vbox-overlay .vbox-next {
    color: #ffffff;
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-color: #707070;
    top: 50%;
    margin-top: -25px;
}

.vbox-overlay .vbox-prev svg {
    margin-left: -4px;
}

.vbox-overlay .vbox-content {
    padding: 0 6%;
}

@media only screen and (max-width: 1220px) {
    .vbox-overlay .vbox-content {
        padding: 0 7%;
    }
}

@media only screen and (max-width: 1000px) {
  .vbox-overlay .vbox-content {
        padding: 0 8%;
    }
}

@media only screen and (max-width: 870px) {
  .article .author .info {
    font-size: 26px;
    line-height: 26px;
  }
  .article .author .info .description {
    font-size: 16px;
    line-height: 130%;
  }
  .article .author .info .city {
    font-size: 20px;
    line-height: 20px;
  }
  .vbox-overlay .vbox-content {
    padding: 0 2%;
  }

  .vbox-overlay .vbox-next, .vbox-overlay .vbox-prev {
    top: 90%;
  }
}

@media only screen and (max-width: 640px) {
  .article .author {
    padding: 20px 24px;
    gap: 12px 24px;
  }
  .article .author .info {
    font-size: 20px;
    line-height: 20px;
  }
  .article .author .info .description {
    font-size: 15px;
  }
  .article .author .info .city {
    font-size: 18px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 550px) {
  .article .author .info .description {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .article .author {
    flex-direction: column;
    align-items: center;
    gap: 30px 0;
  }
  .article .author .photo {
    width: 240px;
    min-width: 240px;
  }
  .article .author .info {
    font-size: 24px;
    line-height: 24px;
    height: auto;
    gap: 20px 0;
  }
  .article .author .info .description {
    font-size: 15px;
  }
  .article .author .info .city {
    font-size: 20px;
    line-height: 20px;
  }
}