.title-separator {
  border-bottom: 1px solid $frenchGray;
  > span {
    display: block;
    width: 300px;
    text-align: center;
    position: relative;
    background-color: white;
    margin: 0 auto -20px auto;
    line-height: 40px;
    height: 40px;
    border: 1px solid $frenchGray;
    color: $frenchGray;
    border-radius: 20px;
    text-transform: uppercase;
  }
}