.section-articles {
  max-width: 1125px;
  margin: 4rem auto 10rem auto;
  display: flex;
  gap: 70px 20px;
  flex-wrap: wrap;
  // justify-content: space-between;
  @media ( max-width: $sw-md-max) {
    max-width: 755px;
  }
  @media ( max-width: $sw-xs-max) {
    max-width: 355px;
  }
}

.section-articles.mb2rem {
  margin-bottom: 2rem;
}

.article-item {
  width: 100%;
  max-width: 360px;
  &__theme-link {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.1rem;
  }
  &__head-link {
    display: block;
    margin: 20px 0;
    color: $cornflowerBlue;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bolder;
  }
  &__arrow {
    padding: 0 20px;
  }
  &__preview-text {
    margin-top: 15px;
  }
  &__preview {
    max-width: 355px;
    margin-bottom: 10px;
    width: 100%;
  }
}