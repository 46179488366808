.s-profile-inner, .s-register-inner, .s-distributor-inner {
    margin-top: 3rem;
    border-top: 1px solid $athensGray;
    padding-top: 8rem;
    padding-bottom: 9.5rem;
    @media (max-width: $sw-sm-max) {
        padding-bottom: 2rem;
    }
    @media (max-width: $sw-xs-max) {
        border-top: none;
        padding-top: 9rem;
    }
    &__error {
      text-align:center;
      color:red;
      height: 30px;
      font-size: 18px;
      margin-top: 10px;
    }
    &.s-profile-contacts {
        background-color: $athensGray;
        padding-top: 8rem;
        padding-bottom: 0;
        margin-top: 8rem;
        .s-ask-question__main-text {
            margin-bottom: 2.2rem;
            margin-left:auto;
            margin-right:auto;
            @media (max-width: $sw-md-max) {
                margin: 0 auto 5rem auto;
            }
        }
        .s-profile-inner__side {
            @media (max-width: $sw-md-max) {
                width: 100%;
            }
        }
        .s-profile-inner__wrapper {
            @media (max-width: $sw-md-max) {
                max-width: 460px;
                margin: 0 auto;
            }
        }
        .form-control {
            background-color: transparent;
        }
    }
    &.s-profile-contacts.subscribe {
        background-color: #ffffff;
        padding-top: 2rem;
        margin-top: 0;
    }
    &.s-profile-registration {
        @media (max-width: $sw-md-max) {
            padding-top: 8rem;
            margin-top: 2rem;
        }
        @media (max-width: $sw-xs-max) {
            padding-top: 5rem;
            padding-bottom: 0;
        }
        .s-ask-question__main-text {
            margin-bottom: 2.2rem;
            @media (max-width: $sw-md-max) {
                margin: 0 auto 5rem auto;
            }
        }
        .s-profile-inner__side {
            @media (max-width: $sw-md-max) {
                width: 100%;
            }
        }
        .s-register-inner__wrapper {
            @media (max-width: $sw-md-max) {
                max-width: 490px;
                margin: 0 auto;
            }
        }
    }
    &__main-wrapper {
        border-bottom: 1px solid $athensGray;
        padding-bottom: 10rem;
        margin-bottom: 5rem;
        @media (max-width: $sw-md-max) {
            border-bottom: none;
            padding-bottom: 8rem;
            margin-bottom: 0;
        }
        @media (max-width: $sw-sm-max) {
            padding-bottom: 6rem;
        }
    }
    &__main-wrapper.noBorder {
        border-bottom: 0;
    }
    &__wrapper {
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        //margin-bottom: 10rem;
        @media (max-width: $sw-md-max) {
            max-width: 1000px;
            padding: 0 2rem;
            margin-bottom: 7rem;
        }
        @media (max-width: $sw-sm-max) {
            max-width: 100%;
            margin-bottom: 5rem;
        }
        @media (max-width: $sw-xs-max) {
            padding: 0 2rem;
            margin-bottom: 3.5rem;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__tab-content {
      display: none;
    }
    &__tab-content.active {
      display: flex;
    }
    &__side {
        width: 440px;
        @media (max-width: $sw-sm-max) {
            width: 46.3%;
        }
        @media (max-width: $sw-xs-max) {
            width: 100%;
        }
        &.full-side {
            width: 100%;
        }
        &.submit-group-side {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 1rem;
            gap: 0 2rem;
            @media (max-width: $sw-md-max) {
                flex-wrap: wrap;
                justify-content: center;
            }
            .btn {
                width: 160px;
                @media (max-width: $sw-md-max) {
                    margin-top: 3rem;
                }
            }
        }
        &.submit-side {
            text-align: center;
            width: 100%;
            max-width: 960px;
            margin: 0 auto;
            @media (max-width: $sw-md-max) {
                text-align: right;
                max-width: 1000px;
                padding: 0 2rem;
            }
            @media (max-width: $sw-sm-max) {
                max-width: 100%;
                padding: 0 5rem;
            }
            @media (max-width: $sw-xs-max) {
                padding: 0 2rem;
            }
            @media (max-width: $sw-xx-max) {
                text-align: center;
            }
        }
    }
    &__label {
        span {
            font-size: 1rem;
            @media (max-width: $sw-xs-max) {
                font-size: 1.4rem;
            }
        }
    }
    &__input {
        margin-bottom: 30px;
        @media (max-width: $sw-xs-max) {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 35px;
            }
        }
        // input, span, textarea {
        //     width: 100%;
        //     min-width: auto !important;
        // }
        // input.form-control {
        //     @media (max-width: $sw-sm-max) {
        //         font-size: 1.2rem !important;
        //     }
        //     @media (max-width: $sw-xs-max) {
        //         font-weight: 300 !important;
        //         font-size: 2.4rem !important;
        //         padding-left: 0;
        //     }
        // }
        // textarea.form-control {
        //     resize: none;
        //     min-height: auto;
        //     height: 3.2rem;
        //     @media (max-width: $sw-sm-max) {
        //         font-size: 1.2rem !important;
        //     }
        //     @media (max-width: $sw-xs-max) {
        //         font-weight: 300 !important;
        //         font-size: 2.4rem !important;
        //         padding-left: 0;
        //     }
        // }
    }
    &__select {
        .select-wrap {
            position: relative;
            &:after {
                content: '';
                display: block;
                background-image: url('../img/profile/chevron-sel.svg');
                background-repeat: no-repeat;
                background-size: 11px 8px;
                width: 11px;
                height: 8px;
                position: absolute;
                top: 50%;
                right: 11px;
                transform: translate(0, -50%);
                pointer-events: none;
            }
        }
        select.form-control {
            min-width: auto !important;
            position: relative;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            -webkit-border-radius: 0px;
            font-weight: 500 !important;
            background-color: transparent;
						padding-bottom: 0 !important;
						box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
            @media (max-width: $sw-sm-max) {
                font-size: 1.2rem !important;
            }
            @media (max-width: $sw-xs-max) {
                font-weight: 300 !important;
                font-size: 2.4rem !important;
                padding-left: 0;
            }
        }
    }
    &__checkbox {
        display: flex;
        align-items: flex-start;
        .checkbox-group {
            display: flex;
            align-items: center;
        }
    }
    &__title {
        color: $charade;
        font-size: 1.4rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 3.8rem;
        @media (max-width: $sw-xs-max) {
            font-size: 1.8rem;
        }
    }

  .profile_custom-nav__inner-tabs {
    .section-custom-nav {
      background-color:white;
    }
    padding-bottom: 6rem;
  }
}
.s-card-payment__forms {
	.s-profile-inner__side-checkout, .s-profile-inner__wrapper-checkout-req {
		display: none;
	}
	.s-profile-inner-checkout, .s-profile-inner__main-wrapper-checkout {
		border: none;
		padding-bottom: 0;
	}
	.s-profile-inner-checkout {
		padding-top: 6rem;
	}
	.s-profile-inner__wrapper-checkout {
		margin-bottom: 4rem;
	}
	.s-profile-inner__main-wrapper-checkout {
		margin-bottom: 0;
	}

    &__error {
      text-align:center;
      color:red;
      height: 30px;
      margin-top: 10px;
    }
}

.s-register-inner {
    &__wrapper {
        .full-side {
            display: flex;
            justify-content: center;
            margin-top: 80px;
            @media (max-width: $sw-md-max) {
                margin-top: 50px;
            }
            @media (max-width: $sw-xs-max) {
                margin-top: 25px;
            }
            a {
                color: #2b2b38;
                &:hover {
                    @media (min-width: $sw-sm-min) {
                        text-decoration: none;
                    }
                }
            }
        }
        .btn {
            width: 220px;
        }
        .s-profile-inner__input {
            width: 100%;
            max-width: 440px;
            &.input-checkbox {
                display: flex;
                align-items: flex-end;
            }
            &.fill-width {
              max-width: 100%;
            }
        }
    }
    &__main-wrapper {
        border-bottom: none;
        padding-bottom: 0;
    }
}
.s-distributor-inner {
    &__wrapper {
        @media (max-width: $sw-md-max) {
            width: 100%;
            max-width: 495px;
            margin: 0 auto;
        }
        .btn {
            width: 160px;
        }
    }
}


.s-profile-inner__input-select {
	form {
		width: 100%;
		height: 100%;
		padding-bottom: 75px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: radial-gradient(at top, #b5e6cf, #9dddad);
	}
	
	.select {
		width: 225px;
		height: 40px;
		cursor: pointer;
		background-color: white;
		box-shadow: 0 2px 0 white;
		border-radius: 2px;
		
		&_expand {
			width: 0;
			height: 40px;
			position: absolute;
			top: 0;
			right: 0;
			
			&::after {
				content: '\003E';
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(-50%, -50%) rotate(90deg) scaleY(1.75);
				color: #3e3e3e;
				font-size: 28px;
				pointer-events: none;
				z-index: 2;
				transition: all 250ms cubic-bezier(.4,.25,.3,1);
				opacity: .6;
			}
			
			&:hover::after {opacity: 1}
	
			&:checked::after {transform: translate(-50%, -50%) rotate(90deg) scaleX(-1) scaleY(1.75);}
		}
		
		&_expandLabel {
			display: block;
			width: 100%;
			height: 40px;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
		}
		
		&_close {display: none}
		
		&_closeLabel {
			width: 100vw;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			display: none;
		}
		
		&_items {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 2px;
			padding-top: 40px;
			margin-top: 30px;
		}
		
		&_input {display: none}
		
		&_label {
			transition: all 250ms cubic-bezier(.4,.25,.3,1);
			display: block;
			height: 0;
			font-size: 1.2rem;
			line-height: 40px;
			overflow: hidden;
			color: #3e3e3e;
			background-color: #fff;
			cursor: pointer;
			padding-left: 20px;
			
			&-placeholder {
				height: 40px;
				vertical-align: middle;
				position: absolute;
				top: 0;
				left: 0;
				opacity: .6;
				background-color: transparent;
			}
		}
		
		&_expand:checked {
			+ .select_closeLabel {
				display: block;
				
				+ .select_options {
					.select_label {
						height: 40px;
						&:hover {background-color: #f7f7f7}
					}
					
					+ .select_expandLabel {display: none}
				}
			}
		}
		
		&_input:checked + .select_label {
			height: 40px;
			margin-top: -40px;
		}
	}
}
