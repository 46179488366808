.ajax-form {
  &__after {
    display: none;
  }
  &__error {
    display: none;
  }
  &_success {
    .ajax-form__after {
      display: inherit;
    }
    .ajax-form__form {
      display: none;
    }
  }
  &_error {
    .ajax-form__error {
      display: inherit;
    }
  }
}