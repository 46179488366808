.s-authorization, .s-profile-inner {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 9.2rem;
	padding-bottom: 19rem;
	@media (max-width: $sw-sm-max) {
		padding-bottom: 14rem;
	}
	&__head {
		h2 {
			color: #3e3e48;
			font-size: 36px;
			font-weight: 900;
			line-height: 25px;
			text-transform: uppercase;
			letter-spacing: 3.6px;
			margin: 0;
		}
	}
	&__form {
		padding-top: 8rem;
		width: 100%;
		max-width: 640px;
		margin: 0 auto;
		@media (max-width: $sw-md-max) {
			max-width: 445px;
			padding-left: 2rem;
			padding-right: 2rem;
		}
		@media (max-width: $sw-xs-max) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	&__error {
		margin: 0 auto;
		max-width: 440px;
		color: red;
	}

	&__login, &__pass {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		&-form {
			width: 100%;
			max-width: 440px;
			margin: 0 auto;
		}
	}
	&__pass-link, &__login-link {
		// margin-left: 86px;
		text-align: left;
		width: 113px;
		@media (max-width: $sw-md-max) {
			display: none;
		}
		a {
			color: #4d4d57;
			font-size: 10px;
			font-weight: 300;
			text-decoration: none;
			text-transform: uppercase;
			border-bottom: 1px solid #b2b2c3;
			letter-spacing: 2px;
		}
	}
	&__login-label, &__pass-label, &__label {
		margin-bottom: 5px;
		span {
			color: #b2b2c3;
			font-size: 10px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 2px;
			@media (max-width: $sw-sm-max) {
				font-size: 14px;
			}
		}
	}
	&__submit {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		margin-top: 6rem;
		button {
			padding: 0 3.6rem;
			@media (max-width: $sw-sm-max) {
				font-size: 22px;
				padding: 0 9.8rem;
				height: 8rem;
			}
		}
	}
	&__pass {
			margin-top: 27px;
	}
	&__link-m-pass {
		@media (max-width: $sw-sm-max) {
			margin-bottom: 2.7rem;
		}
		a {
			color: #2b2b38;
			font-size: 12px;
			font-weight: 300;
			line-height: 18px;
			text-decoration: underline;
			@media (max-width: $sw-sm-max) {
				font-size: 18px;
			}
		}
	}
	&__link-m-reg {
		text-align: center;
		span {
			color: #b2b2c3;
			font-size: 12px;
			font-weight: 300;
			line-height: 18px;
			@media (max-width: $sw-sm-max) {
				font-size: 18px;
			}
		}
		a {
			color: #2b2b38;
			font-size: 12px;
			font-weight: 300;
			line-height: 18px;
			text-decoration: underline;
			@media (max-width: $sw-sm-max) {
				font-size: 18px;
			}
		}
	}
	&__link-m {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			margin-top: 35px;
			@media (max-width: $sw-sm-max) {
				margin-top: 7rem;
			}
	}
}
.authorization_head {
		display: block;
}

.s-authorization__pass-form, .s-authorization__login-form, .s-profile-inner__input {
	.form-control {
		margin: 0;
		padding: 0;
		border: none;
		height: 2.2rem;
		line-height: 1;
		font-weight: 400;
		border-radius: 0;
		font-size: 1.2rem;
		letter-spacing: .15rem;
		outline: none !important;
		box-shadow: none !important;
		background-color: transparent;
		border-bottom: 1px solid #b2b2c3;
		// @media (max-width: $sw-sm-max) {
		// 	font-size: 26px;
		// }
	}
}