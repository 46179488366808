.revive-tooltip {
  background-color: #1c1c1f;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  position: absolute;
  visibility: hidden;
  z-index: 99999;
  white-space: nowrap;

  &_show {
    > .revive-tooltip {
      visibility: visible;
    }
  }

  &__wrapper:hover {

    > .revive-tooltip {
      visibility: visible;
    }
  }
}