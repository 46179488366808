.breadcrumb__items{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.breadcrumb__item{
	display: flex;
	flex-wrap: wrap;
	font-size: 1rem;
	align-items: center;
	line-height: 1.6rem;
	letter-spacing: .2rem;
	text-transform: uppercase;
	&:not(:first-child){
		&:before{
			content: '>';
			margin-left: 1.8rem;
			margin-right: 1.6rem;
		}
	}
	span{
		color: $stormGray;
	}
}