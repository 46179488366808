.s-faq-collapse {
	padding-top: 7.7rem;
	border-top: 1px solid #f2f2f7;
	@media (max-width: $sw-md-max) {
		padding-top: 5rem;
	}
	@media (max-width: $sw-xs-max) {
		padding-top: 6.5rem;
	}
}
.faq-collapse {
	&__head-desc {
		width: 100%;
		max-width: 50%;
		margin: 0 auto;
		padding: 0 12rem;
		text-align: justify;
		@media (max-width: $sw-md-max) {
			padding: 0;
		}
		@media (max-width: $sw-sm-max) {
			max-width: 52%;
		}
		@media (max-width: $sw-xs-max) {
		 max-width: 93%;
		}
		p {
			@media (max-width: $sw-sm-max) {
				line-height: 2.4rem;
			}
			@media (max-width: $sw-xs-max) {
				font-size: 1.8rem;
			}
		}
	}
	&__main {
		padding: 7rem 0 6.9rem 0;
		max-width: 50%;
		width: 100%;
		margin: 0 auto;
		@media (max-width: $sw-md-max) {
			padding: 6.2rem 0 6.7rem 0;
			max-width: 79%;
		}
		@media (max-width: $sw-sm-max) {
			padding: 6.9rem 0 6.7rem 0;
			max-width: 89%;
		}
		@media (max-width: $sw-xs-max) {
			padding: 8rem 0 6.7rem 0;
    	max-width: 92%;
		 }
	}
	&__header {
		span {
			font-size: 1.2rem;
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 2.3px;
			@media (max-width: $sw-sm-max) {
				letter-spacing: 2.1px;
			}
			@media (max-width: $sw-xs-max) {
				font-size: 2rem;
				line-height: 2.5rem;
			}
			@media (max-width: $sw-xs-max) {
				letter-spacing: 3px;
			}
		}
		button {
			padding: 1.8rem 26rem 2.2rem 0;
			text-align: left;
			width: 100%;
			position: relative;
			@media (max-width: $sw-sm-max) {
				padding: 1.8rem 19rem 2.2rem 0;
			}
			@media (max-width: $sw-xs-max) {
				padding: 3.8rem 5rem 3.7rem 0;
			}
		}
	}
	&__items {
		.collapse-box{
			input:checked + .collapse-box__button + .collapse-box__content{
				max-height: 220rem;
				text-align: justify;
			}
		}
	}
	&__disabled-link {
		text-decoration: none;
	}
	&__item {
		border-top: 1px solid #dfdfe8;
		&-last {
			border-bottom: 1px solid #dfdfe8;
		}
	}
	&__body {
		padding: 4.5rem 28rem 4rem 6.5rem;
		@media (max-width: $sw-sm-max) {
			padding: 0.5rem 4rem 4rem 2rem
	}
		@media (max-width: $sw-xs-max) {
			padding: 1.5rem 0rem 4rem 0rem;
	}
		p {
			font-size: 1.2rem;
			line-height: 1.8rem;
			@media (max-width: $sw-sm-max) {
				font-size: 1.4rem;
				line-height: 20px;
			}
				@media (max-width: $sw-xs-max) {
					font-size: 1.8rem;
					line-height: 24px;
			}
		}
		ul {
			padding: 0;
			margin: 0;
			padding: 1.25rem 0;
			li {
				font-size: 1.2rem;
				line-height: 1.8rem;
				padding: 1.25rem 0;
				@media (max-width: $sw-sm-max) {
					font-size: 1.4rem;
					line-height: 20px;
			}
				@media (max-width: $sw-xs-max) {
					font-size: 1.8rem;
					line-height: 24px;
			}
			}
		}
		b {
			font-size: 1.2rem;
			font-weight: 900;
			@media (max-width: $sw-sm-max) {
				font-size: 1.4rem;
				line-height: 20px;
		}
			@media (max-width: $sw-xs-max) {
				font-size: 1.8rem;
				line-height: 24px;
		}
		}
	}
}

.plus-minus-toggle {
  cursor: pointer;
  height: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 6px;
	width: 25px;
	@media (max-width: $sw-xs-max) {
		right: 16px;
	}
  &:before,
  &:after{
    background: #4d4d57;
    content: '';
    height: 1px;
		left: auto;
		right: 0;
    position: absolute;
		top: 50%;
		transform: translateY(-50%);
    width: 11px;
		transition: transform 500ms ease;
		@media (max-width: $sw-xs-max) {
			width: 23px;
			height: 3px;
		}
  }
  &:after {
    transform-origin: center;
	}
	&:after {
		transform: rotate(90deg);
	}
	&:before {
		transform: rotate(180deg);
	}
 
}
button[aria-expanded="true"]  {
	.plus-minus-toggle {
		&:after {
			transform: rotate(0deg);
    }
	}
}



.faq_collapse_label {
	@media (max-width: $sw-xx-max) {
		padding-right: 5rem;
	}
}
