.s-404 {
	background-color: #1c1c1f;
	&__wrapper {
		width: 100%;
		max-width: 50%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding-top: 8rem;
		//margin-bottom: -5.6rem;
		@media (max-width: $sw-md-max) {
			//margin-bottom: -15.3rem;
			padding-bottom: 20rem;
		}
		@media (max-width: $sw-lg-max) {
			padding-top: 4rem;
			//margin-bottom: -10.3rem;
		}
		@media (max-width: $sw-md-max) {
			max-width: 100%;
			//margin-bottom: -15.3rem;
		}
		@media (max-width: $sw-sm-max) {
			max-width: 99%;
			padding-top: 15rem;
			padding-bottom: 22.4rem;
		}
	}
	&__head-title {
		h2 {
			color: #b2b2c3;
			font-size: 36px;
			font-weight: 900;
			letter-spacing: 3.6px;
			margin-bottom: 0;
		}
	}
	&__head-subtitle {
		@media (max-width: $sw-sm-max){
			margin-top: 3px;
		}
		span {
			color: #b2b2c3;
			font-weight: 300;
			letter-spacing: 1.4px;
			font-size: 14px;
			@media (max-width: $sw-sm-max) {
				text-transform: uppercase;
			}
		}
	}
	&__desc-block {
		margin-top: 5.3rem;
		span {
			font-size: 11px;
			font-weight: 400;
			line-height: 25px;
			text-transform: uppercase;
			color: #b2b2c3;
			@media (max-width: $sw-sm-max){
				font-size: 17px;
				text-transform: inherit;
			}
		}
	}
	&__link-block {
		display: flex;
		flex-direction: column;
		margin-top: 8rem;
		@media (max-width: $sw-sm-max) {
			margin-top: 7.7rem;
		}
		a {
			color: #b2b2c3;
			font-size: 14px;
			font-weight: 300;
			letter-spacing: 2px;
			text-decoration: underline;
			@media (max-width: $sw-sm-max) {
				text-transform: uppercase;
				font-size: 17px;
			}
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}
	&__img-block {
		margin-top: 9.6rem;
		@media (max-width: $sw-md-max) {
			margin-top: 7.6rem;
		}
		@media (max-width: $sw-sm-max) {
			margin-top: 4.6rem;
		}
	}
}