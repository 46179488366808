.form-msg {
	text-align:center;
	height: 30px;
	font-size: 18px;
	margin: 26px 0;
	&.error-msg {
		color:red;
	}
	&.success-msg {
		color:green;
	}
}


.form-control {
	transition: background-color $transition_duration ease, color $transition_duration ease;
	// box-shadow: 0.15rem 0.15rem 0 0.05rem rgba(0, 0, 0, 0.5) !important;
	background-color: $oxfordBlue;
	padding-right: 1.6rem;
	padding-left: 1.6rem;
	border-radius: 1rem;
	line-height: 4.8rem;
	// text-align: center;
	// font-weight: 300;
	// color: $glacier;
	font-size: 2rem;
	height: 4.8rem;
	border: none;

	// @media ( max-width: $sw-sm-max) {
	// 	font-size: 1.8rem;
	// }

	&::placeholder{
		transition: opacity $transition_duration ease;
		opacity: 1;
	}
	&:hover {
		// background-color: $oxfordBlue + 20%;
	}
	&[readonly] {
		&:hover {}
		&:focus {}
		&:active {}
	}
	&:focus {
		// background-color: $oxfordBlue + 20%;
		// color: $glacier;
		// box-shadow: none;

		&::placeholder{
			opacity: 0;
		}
	}
	&:active {
		// box-shadow: none;
	}
	&.error {
		border-color: #ff0000 !important;
		background-color: #ffdede;
	}

	&[contenteditable="true"],
	// textarea& {}
	&[contenteditable="true"] {}
	// textarea& {
	// 	min-height: 120px;
	// 	max-height: 500px;
	// 	resize: vertical;
	// }
	// input& {
	// 	height: 34px;
	// }
	&:disabled,
	&[disabled],
	&.disabled {
		.form-control {
			pointer-events: none;
			cursor: default;
			opacity: 0.5;
		}
	}
}
.form-group {
	// margin-bottom: 15px;
	&:disabled,
	&[disabled],
	&.disabled {
		.form-control {
			pointer-events: none;
			cursor: default;
			opacity: 0.5;
		}
	}
}
.input-wrap,
.select-wrap {
	position: relative;
}
.select-wrap {}
.input-wrap {
	& > .ico {}
}
.bootstrap-select {
	position: relative;
	display: block;

	& * {
		outline: 0 !important;
	}
	.btn-light:not(:disabled):not(.disabled):active,
	.btn-light:not(:disabled):not(.disabled).active,
	&.show > .btn-light.dropdown-toggle {
		background-color: $oxfordBlue + 20% !important;
		color: $glacier !important;
	}
	& > select {
		height: 0 !important;
		width: 0 !important;
		visibility: hidden;
		position: absolute;
		left: -100vw;
		z-index: -1;
	}
	& > .dropdown-menu {
		box-shadow: 0.15rem 0.05rem 0 0.05rem rgba(0, 0, 0, 0.2);
		background-color: $tePapaGreen;
		border-radius: 1rem;
		width: 100%;
		padding: 0;

		.inner {
			background-color: transparent !important;
			position: relative;
			border: none;
			width: 100%;
			padding: 0;
		}

		&::-webkit-scrollbar {
			width: 0.5rem;
		}
		&::-webkit-scrollbar-button {
			display: none;
		}
		&::-webkit-scrollbar-track {
			background-color: #f2f2f2;
			border-radius: 0.5rem;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #949494;
			border-radius: 0.5rem;
		}
		& > .inner {
			display: block;
			background-color: transparent !important;
			position: relative;
			padding-bottom: 0;
			box-shadow: none;
			border-radius: 0;
			font-weight: 400;
			line-height: 1.2;
			font-size: 14px;
			padding-top: 0;
			margin-top: 0;
			border: 0;

			& > li {
				&.selected {
					a {
						background-color: #f0f0f0;
						color: #9bb3d2;

						&:hover {
							// background-color: #f0f0f0;
							// color: #9bb3d2;
							background-color: #243746;
							color: #fff;
						}
					}
				}
				a {
					text-decoration: none;
					padding: 7px 15px;
					cursor: pointer;
					display: block;
					color: inherit;

					&:hover {
						// background-color: #f0f0f0;
						// color: #9bb3d2;
						background-color: #243746;
						color: #fff;
					}
				}
			}
		}
	}
	ul.dropdown-menu {
		.dropdown-item {
			transition: background-color $transition_duration ease;
			padding: 1.4rem 1.6rem;
			font-weight: 300;
			cursor: pointer;
			color: $glacier;

			&:hover {
				background-color: $oxfordBlue + 20%;
				color: $glacier;
			}
			&:focus {}
			&:active {}
			&.active {
				background-color: $oxfordBlue;

				&:hover {
					background-color: $oxfordBlue + 20%;
					color: $glacier;
				}
			}
		}
	}
	.dropdown-toggle {
		box-shadow: 0.15rem 0.15rem 0 0.05rem rgba(0, 0, 0, 0.5) !important;
		background-color: $oxfordBlue;
		padding-right: 4.6rem;
		padding-left: 1.6rem;
		border-radius: 1rem;
		line-height: 4.8rem;
		position: relative;
		line-height: 2.4em;
		text-align: left;
		font-weight: 300;
		font-size: 2rem;
		color: $glacier;
		height: 4.8rem;
		border: none;
		width: 100%;

		@media ( max-width: $sw-sm-max) {
			font-size: 1.8rem;
			line-height: 2em;
		}

		&:hover {
			background-color: $oxfordBlue + 20%;
		}
		&:focus {}
		&:active {}

		&:after {
			display: none;
		}
		&:before {
			font-family: 'icomoon' !important;
			-webkit-transform: rotate(90deg);
			transform: rotate(90deg);
			margin-bottom: auto;
			position: absolute;
			font-size: 2.4rem;
			margin-top: auto;
			content: "\e910";
			display: block;
			right: 1.2rem;
			bottom: 0;
			top: 4px;
		}
		.bs-caret {
			display: none;
		}
	}
}
label {
	margin-bottom: 0;
	font-weight: 400;
	cursor: pointer;

	&.error {
		color: #ff0000;
	}
}
.hidden-input {
    visibility: hidden !important;
	position: absolute !important;
	z-index: -1 !important;
    opacity: 0 !important;
    height: 0 !important;
    width: 0 !important;
    left: 0 !important;
    top: 0 !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}
.checkbox-label {
	position: relative;
	padding-left: 3rem;

	&:disabled,
	&[disabled],
	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}

	.check-icon {
		border: solid 0.1rem #979797;
		border-radius: 0.4rem;
		position: absolute;
		display: block;
		height: 18px;
		width: 18px;
		left: 0;
		top: 4px;

		&:before {
			transition: opacity $transition_duration ease;
			transform: translateX(-50%) translateY(-50%);
			position: absolute;
			display: block;
			width: 9px;
			height: 8px;
			border-radius: 2px;
			background-color: #b2b2c3;
			left: 50%;
			margin: auto;
			content: '';
			opacity: 0;
			top: 50%;
			bottom: 0;
			right: 0;
		}
	}
	.check-value {
		transition: color $transition_duration ease;
		color: #323232;
	}
	&.label_grey {
		.check-value {
			color: #979797;
		}
	}
	input {
		&:checked {
			& ~ .check-icon {
				&:before {
					opacity: 1;
				}
			}
		}
		&:disabled {
			& ~ .check-icon {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
			& ~ .check-value {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
		}
	}
}
.radio-label {
	padding-left: 2rem;
	position: relative;

	&:disabled,
	&[disabled],
	&.disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}

	.check-icon{
		border: solid 1px #0171a6;
		border-radius: 50%;
		position: absolute;
		display: block;
		height: 1.3rem;
		width: 1.3rem;
		top: 0.4rem;
		left: 0;

		&:before{
			transition: opacity $transition_duration ease;
			background-color:  #0171a6;
			border-radius: 50%;
			position: absolute;
			display: block;
			height: 0.5rem;
			width: 0.5rem;
			margin: auto;
			content: '';
			opacity: 0;
			bottom: 0;
			right: 0;
			left: 0;
			top: 0;
		}
	}
	.check-value {
		transition: color $transition_duration ease;
		color: #979797;
	}
	&.label_grey {
		.check-value {
			color: #979797;
		}
	}
	input {
		&:checked {
			& ~ .check-icon {
				&:before {
					opacity: 1;
				}
			}
		}
		&:disabled {
			& ~ .check-icon {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
			& ~ .check-value {
				pointer-events: none;
				cursor: default;
				opacity: 0.5;
			}
		}
	}
}
.dp-arrow {
	transition: color $transition_duration ease;
	outline: none !important;
	margin-bottom: auto;
	position: absolute;
	margin-top: auto;
	cursor: pointer;
	color: $glacier;
	display: block;
	// height: 2rem;
	height: 100%;
	width: 3rem;
	z-index: 2;
	bottom: 0;
	top: .2rem;

	&:hover {
		color: $linkWater;

		& ~ .form-control {
			background-color: $oxfordBlue + 20%;
		}
	}
	&:focus {}
	&:active {}

	&-prev {
		// transform: rotate(135deg);
		left: 0;
	}
	&-next {
		// transform: rotate(-45deg);
		right: 0;
	}
	i{
		font-size: 2.4rem;
	}
	&:before {
		// border-bottom: 0.1rem solid currentColor;
		// border-right: 0.1rem solid currentColor;
		// position: absolute;
		// display: block;
		// margin: auto;
		// height: 1rem;
		// width: 1rem;
		// content: '';
		// bottom: 0;
		// right: 0;
		// left: 0;
		// top: 0;
	}
}


.filter-option-inner-inner{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.dropdown-item{
	overflow: hidden;
	position: relative;
	white-space: nowrap;
	text-overflow: ellipsis;
	.bs-ok-default.check-mark{
		top: 50%;
		opacity: 0;
		width: .8rem;
		right: 1.2rem;
		height: 1.4rem;
		display: block;
		position: absolute;
		visibility: hidden;
		margin-top: -.8rem;
		transform: rotateZ(40deg);
		border-right: .2rem solid #fff;
		border-bottom: .2rem solid #fff;
		.selected:not(.active) &{
			opacity: 1;
			visibility: visible;
		}
	}
}
